<app-header></app-header>
<section *ngIf="!mfaFlag">
  <div class="row">
    <div class="col-md-4"></div>

    <div class="col-md-4">
      <div class="card my-5">
        <div class="card-body">
          <h5 class="card-title text-center">Sign In Form</h5>
          <form class="my-3" [formGroup]="signinForm" (ngSubmit)="signInNew()" novalidate >
            <div class="form-group">
              <mat-form-field appearance="legacy" style="font-size: 16px">
                <mat-label>User Name</mat-label>
                <input type="text" matInput formControlName="username" placeholder="User Name" name="username"
                  [ngClass]="{
                    'is-invalid': submitted && formControls.username.errors
                  }" />
                <mat-error *ngIf="formControls.username.errors" class="invalid-feedback error">
                  <div *ngIf="formControls.username.errors.required">
                    Username is <strong>required</strong>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field appearance="legacy" style="font-size: 16px">
                <mat-label>Password</mat-label>
                <input type="password" matInput formControlName="password" placeholder="Password" name="password"
                  [ngClass]="{
                    'is-invalid': submitted && formControls.password.errors
                  }" [type]="hide ? 'password' : 'text'" />
                <mat-icon matSuffix (click)="hide = !hide" style="cursor: pointer">{{ hide ? "visibility_off" :
                  "visibility" }}
                </mat-icon>
                <mat-error *ngIf="formControls.password.errors" class="invalid-feedback error">
                  <div *ngIf="formControls.password.errors.required">
                    Password is <strong>required</strong>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <button [disabled]="!signinForm.valid || submitted" class="btn-block" mat-raised-button color="accent" type="submit"
              style="font-size: 16px">
              Sign In 
              <i class="fa fa-lock" aria-hidden="true"></i>
            </button>
          </form>
          <p class="text-danger" >{{ error }}</p>
          <a class="text-primary text-center" [routerLink]="['/extra-layout/forgot-password']">Forgot password..?</a>
        </div>
      </div>
    </div>

    <div class="col-md-4"></div>
  </div>
</section>

<section *ngIf="mfaFlag && !userAuthenticated">
  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4">
      <div class="card my-5">
        <div class="card-body">
          <h5 class="card-title text-center">MFA Code</h5>
          <form class="my-3" [formGroup]="codeForm" (ngSubmit)="confirmationCode()" novalidate>
            <div class="form-group">
              <mat-form-field appearance="legacy" style="font-size: 16px">
                <mat-label>User Name</mat-label>
                <input type="text" matInput formControlName="code" placeholder="Confirmation Code" name="code"
                  [ngClass]="{
                    'is-invalid': submitted && formControls1.code.errors
                  }" />
                <mat-error *ngIf="formControls1.code.errors" class="invalid-feedback error">
                  <div *ngIf="formControls1.code.errors.required">
                    Code is <strong>required</strong>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <button [disabled]="!codeForm.valid" class="btn-block" mat-raised-button color="accent" type="submit"
              style="font-size: 16px">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-4"></div>
  </div>
</section>

<app-footer class="fixed-bottom"></app-footer>
