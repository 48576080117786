import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Graph } from '../../../model/small-molecule.model';
import * as Highcharts from 'highcharts';


declare var require: any;
const More = require('highcharts/highcharts-more');
More(Highcharts);

import Histogram from 'highcharts/modules/histogram-bellcurve';
Histogram(Highcharts);

const Exporting = require('highcharts/modules/exporting');
Exporting(Highcharts);

const ExportData = require('highcharts/modules/export-data');
ExportData(Highcharts);

const Accessibility = require('highcharts/modules/accessibility');
Accessibility(Highcharts);

@Component({
  selector: 'app-graph-dialog',
  templateUrl: './graph-dialog.component.html',
  styleUrls: ['./graph-dialog.component.scss']
})
export class GraphDialogComponent implements OnInit {
  public refinedData;
  public processedData = []
  graph_options = [];
  graphData = [];
  public g_data: Graph;
  public activity;
  public xData;
  public label;
  optionsh: any;
  optionsl: any;
  optionsb: any;
  public subplot = [];
  highcharts;

  constructor(private dialogRef: MatDialogRef<GraphDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) {
    this.graphData = data.name;
    dialogRef.afterClosed().subscribe(() => {
      this.clearData();
    })

  }

  @HostListener('document:keyup.escape') onClose() {
    this.onCancel();
  }


  clearData() {
    this.graphData.length = 0;
  }

  onCancel() {
    this.dialogRef.close();
    this.clearData();
  }

  ngOnInit() {

    // for (const graph of this.graphData) {
    //   const graphObject = JSON.parse(graph);
    //   if (graphObject.type === 'HIST') {
    //     this.graph_options.push(this.histogramChart(graphObject));
    //   } else if (graphObject.type === 'GROUP_BOX') {
    //     this.BoxGraph(graphObject);
    //   } else if (graphObject.type === 'LINE') {
    //     this.graph_options.push(this.lineChart(graphObject));
    //   } else if (graphObject.type === 'GROUP') {
    //     this.graph_options.push(this.subplotChart(graphObject));

    //   } else {
    //     console.log('Error Occured while generating charts!')
    //   }
    // }

  }

  histogramChart(data) {
    this.g_data = data;
    this.highcharts = Highcharts;
    this.optionsh = {
      chart: {
        zoomType: 'xy',
      },
      title: {
        text: `${this.g_data.panel_name} Tukey Cofficient: ${this.g_data.co_efficient}`
      },
      xAxis: [{
        title: {
          text: null
        },
        visible: true,
        zoomEnabled: true
      }, {
        title: {
          text: null
        },
        opposite: false,
        visible: true
      }],

      yAxis: [{
        title: {
          text: 'Frequency'
        },
        visible: true
      }, {
        title: {
          text: null
        },
        opposite: false,
        visible: true
      }],
      plotOptions: {
        histogram: {
          accessibility: {
            pointDescriptionFormatter: (point) => {
              const ix = point.index + 1,
                x1 = point.x.toFixed(3),
                x2 = point.x2.toFixed(3),
                val = point.y;
              return ix + '. ' + x1 + ' to ' + x2 + ', ' + val + '.';
            }
          }
        }
      },
      series: [{
        name: 'Histogram',
        type: 'histogram',
        zoomType: 'xy',
        xAxis: 1,
        yAxis: 1,
        baseSeries: 's1',
        binWidth: this.g_data.bins,
        zIndex: -1
      }, {
        name: 'Data',
        visible: false,
        type: 'scatter',
        data: this.g_data.data,
        id: 's1',
        // marker: {
        //     radius: 1.5
        // }
      }]

    }
    return this.optionsh;

  }

  BoxGraph(data) {

    this.g_data = data;
    this.highcharts = Highcharts;

    const catagories = new Array;
    const b_data = new Array;
    const a_outliar = new Array;
    const b_outliar = new Array;

    // tslint:disable-next-line: no-shadowed-variable
    for (const data of this.g_data.box_data) {
      b_data.push(data[1]);
      catagories.push(data[0]);
    }

    for (const a_out of this.g_data.analytical_outlier) {
      a_outliar.push(a_out);
    }

    for (const b_out of this.g_data.biological_outlier) {
      b_outliar.push(b_out);
    }

    for (let i = 0; i < b_data.length; i++) {
      // this.boxChart(catagories[i], b_data[i], a_outliar[i], b_outliar[i]);
      this.graph_options.push(this.boxChart(catagories[i], b_data[i], a_outliar[i], b_outliar[i]))
    }

  }

  boxChart(category, b_data, a_out, b_out) {

    this.highcharts = Highcharts;

    this.optionsb = {
      chart: {
        zoomType: 'xy',
      },
      title: {
        text: `Panels for Tuckey Coeff: ${this.g_data.co_efficient}`
      },

      legend: {
        enabled: false
      },

      xAxis: {
        categories: category,
        zoomEnabled: true,
        title: {
          text: 'Panels'
        }
      },

      yAxis: {
        title: {
          text: 'Concentration'
        }
      },

      plotOptions: {
        boxplot: {
          boxDashStyle: 'line',
          fillColor: '#F0F0E0',
          lineWidth: 2,
          medianColor: '#0C5DA5',
          medianDashStyle: 'line',
          medianWidth: 2,
          stemColor: '#A63400',
          stemDashStyle: 'dot',
          stemWidth: 1,
          whiskerColor: '#3D9200',
          whiskerLength: '40%',
          whiskerWidth: 2
        }
      },

      series: [{
        name: 'Concentration',
        type: 'boxplot',
        dataSorting: {
          enabled: true
      },
        data: b_data,
        zoomType: 'xy'
      },
      {
        name: 'Analytical',
        color: Highcharts.getOptions().colors[0],
        type: 'scatter',
        data: a_out,
        marker: {
          fillColor: 'white',
          lineWidth: 1,
          lineColor: Highcharts.getOptions().colors[0]
        },
        tooltip: {
          pointFormat: 'Concentration: {point.y}'
        }
      },
      {
        name: 'Biological',
        color: Highcharts.getOptions().colors[1],
        type: 'scatter',
        data: b_out,
        marker: {
          fillColor: 'white',
          lineWidth: 1,
          lineColor: Highcharts.getOptions().colors[1]
        },
        tooltip: {
          pointFormat: 'Concentration: {point.y}'
        }
      }
      ]
    }
    console.log(this.optionsb);
    return this.optionsb;
  }

  lineChart(data) {
    this.g_data = data;
    this.highcharts = Highcharts;

    const optionsl = {
      chart: {
        zoomType: 'xy',
      },
      title: {
        text: `Sensitivity Curve (${this.g_data.panel_name})`
      },

      xAxis: { type: 'logarithmic' },
      yAxis: {
        title: {
          text: 'Response'
        }
      },
      // legend: {
      //   layout: 'vertical',
      //   align: 'right',
      //   verticalAlign: 'middle'
      // },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false
          },
          pointStart: 0
        }
      },

      series: [{
        name: 'Concentration',
        type: 'spline',
        data: this.g_data.g_data,
      },
      {
        name: 'Points',
        type: 'scatter',
        data: this.g_data.s_data,
        marker: {
          radius: 5,
          enabled: true
        }
      },
      {
        name: `Cutpoint ${this.g_data.cut_point[0]}`,
        data: this.g_data.r_data,
        marker: {
          enabled: false
        }
      }],

      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }]
      },
    }

    return optionsl;

  }

  subplotChart(data) {
    this.g_data = data;
    console.log('data', this.g_data);
    this.highcharts = Highcharts;

    const graph_data = this.g_data.g_data;
    const series_data = [];
    for (const i of graph_data) {
      const temp = {
        name: 'Concentration',
        type: 'spline',
        showInLegend: 'test',
        data: i
      }
      series_data.push(temp)
    }

    const optionsg = {
      chart: {
        zoomType: 'xy',
      },
      title: {
        text: `Sensitivity Curve (${this.g_data.panel_name})`
      },

      xAxis: { type: 'logarithmic' },
      yAxis: {
        title: {
          text: 'Response'
        }
      },
      // legend: {
      //   layout: 'vertical',
      //   align: 'right',
      //   verticalAlign: 'middle'
      // },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false
          },
          pointStart: 0
        }
      },

      series: series_data,


      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }]
      },
    }
    return optionsg;

  }
}
