<h4>My Profile</h4>
<section class="my-5">
    <div class="row">
        <div class="col-md-1"></div>
        <mat-card class="col-md-8" style="background-color: rgb(238, 238, 238);">
            <div class="row">
                <div class="col-md-4 text-left d-flex flex-column mx-5">
                    <span class="my-4">Name :</span>
                    <span class="my-4">Email :</span>
                    <span class="my-4">Phone Number :</span>
                    <span class="my-4">password :</span>
                </div>
                <div class="col-md-4 text-left d-flex flex-column mx-5">
                    <span class="my-4" *ngIf="name else nameBlock">{{ name }}</span>
                    <span class="my-4" *ngIf="email else emailBlock">{{ email }}</span>
                    <span class="my-4" *ngIf="phoneNumber else phoneBlock">{{ phoneNumber }}</span>
                    <p class="my-4 d-inline-block" *ngIf="name">
                        ******** 
                        <a class="float-right" [routerLink]="['/extra-layout/forgot-password', {name: 'reset'}]">Change</a>
                    </p> 
                </div>

            </div>
        </mat-card>
    </div>
</section>
<ng-template #nameBlock>
    <div class="ext-left d-flex flex-column">
        <span class="my-4">Username not found.</span>
    </div>
</ng-template>
<ng-template #emailBlock>
    <div class="ext-left d-flex flex-column">
        <span class="my-4">Email not found.</span>
    </div>
</ng-template>
<ng-template #phoneBlock>
    <div class="ext-left d-flex flex-column">
        <span class="my-4">Phone Number not found.</span>
    </div>
</ng-template>
