<div>

  <div class="main-div">

    <!-- <div [style.display]="pageLoaded? 'inline' : 'none'"> -->
    <div *ngIf="pageLoaded ; else spin">
      <div *ngIf="previousAnalysisKeys.length > 0; else noDataFound">
        <app-show-table [searchResults]="previousAnalysisKeys"></app-show-table>
      </div>
      <ng-template #noDataFound>
        <div>
          <h3>No data found for {{signedInUser.username}}</h3>
          <br><br><br><br>
          <p><strong>This section will be active after analysing report/data</strong></p>
        </div>
      </ng-template>
    </div>

    <ng-template #spin>
      <div><mat-spinner  style="margin:0 auto;"
                         mode="indeterminate"  [diameter]="200"></mat-spinner></div>
    </ng-template>
  </div>
</div>