<form [formGroup]="analysisFormGroup" (ngSubmit)="onSubmit(analysisFormGroup.value)" novalidate>

    <!-- <div
        *ngIf="analysisFormGroup.value.analysisType === 'SMR' || analysisFormGroup.value.analysisType === 'LMR' "> -->
    <!-- <div class="row">
        <div class="col-md-9 my-1">
            <mat-form-field>
                <mat-select placeholder="Analysis Subtype" formControlName="analysisSubtype" required
                    disableOptionCentering >
                    <mat-option *ngFor="let obj of getDropDownValues('analysisSubtype')" [value]="obj.name">
                        {{obj.desc}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

    </div> -->


    <div class="col-md-12">
        <mat-radio-group style="margin-top: 10px;" aria-label="Select an option" formControlName="multipleAnalytes">
            <label>Multiple Analytes</label>
            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <mat-radio-button value="Yes">Yes</mat-radio-button>
            <mat-radio-button value="No" [disabled]="analyteNames.length > 1">No</mat-radio-button>
        </mat-radio-group>
    </div>

    <div formArrayName="analyteNames">
        <div class="row" *ngFor="let analyte of analyteNames.controls; let i=index">
            <div class="col-md-9 my-1">
                <mat-form-field>
                    <input matInput placeholder="Analyte Name" type="text" [formControlName]="i" required>
                </mat-form-field>
                
                    
            <mat-form-field>
                    <input matInput placeholder="LLOQ" type="number"  (keyup)=" addLLOQ($event)">
                </mat-form-field>
                  
                
            <mat-form-field>
                    <input matInput placeholder="ULOQ" type="number"   (keyup)=" addULOQ($event)">
                </mat-form-field>
            </div>
            <div class="col-md-3" *ngIf="i>0">
                <button class="btn btn-danger" type="button" (click)="removeAnalyte(i)">Remove
                    Analyte</button>
            </div>
        </div>

        <div *ngIf="analysisFormGroup.controls['multipleAnalytes'].value === 'Yes'">
            <button mat-raised-button type="button" (click)="addAnalyte()">Add Analyte</button>   
        </div>
        
    </div>


    <div class="row">
        <div class="col-md-9 my-1">
            <mat-form-field>
                <input matInput placeholder="Project Code" formControlName="projectCodeSA" required
                    [ngClass]="{ 'is-invalid': analysisFormGroup.controls['projectCodeSA'].hasError }"
                    (ngModelChange)="resetAnalysis()">
                <mat-error *ngIf="analysisFormGroup.controls['projectCodeSA'].errors" class="invalid-feedback error">
                    <div *ngIf="analysisFormGroup.controls['projectCodeSA'].errors.pattern">
                        Must be <strong>Alphanumeric</strong>
                    </div>
                </mat-error>
            </mat-form-field>

        </div>
    </div>

    <div class="row">
        <div class="col-md-6 my-1">
            <mat-form-field>
                <mat-select placeholder="Regression Model" formControlName="regressionModel" disableOptionCentering>
                    <mat-option *ngFor="let obj of getDropDownValues('regressionModel_' + analysistype)"
                        [value]="obj.name">{{obj.desc}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-6 my-1">
            <mat-form-field>
                <input matInput placeholder="Number of Freeze-Thaws Validated" formControlName="freeze_thaw_dialog">
            </mat-form-field>
        </div>

        <div class="col-md-6 my-1">
            <mat-form-field>
                <input matInput placeholder="Long-Term Stability (Days)" formControlName="lts80_dialog">
            </mat-form-field>
        </div>

        <div class="col-md-6 my-1">
            <mat-form-field>
                <input matInput placeholder="Extraction Stability (Hours)" formControlName="extraction_dialog">
            </mat-form-field>
        </div>
        <div class="col-md-6 my-1">
            <mat-form-field>
                <input matInput placeholder="Benchtop Stability (Hours)" formControlName="benchtop_dialog">
            </mat-form-field>
        </div>

        <div class="col-md-6 my-1">

            <mat-form-field>
                <input matInput placeholder="Matrix" formControlName="sampleMatrix"
                    [ngClass]="{ 'is-invalid': analysisFormGroup.controls['sampleMatrix'].errors }">
                <mat-error *ngIf="analysisFormGroup.controls['sampleMatrix'].errors" class="invalid-feedback error">
                    <div *ngIf="analysisFormGroup.controls['sampleMatrix'].errors.pattern">
                        Only <strong>Alphabets</strong>
                    </div>
                </mat-error>
            </mat-form-field>

        </div>
        <div class="col-md-6 my-1">

            <mat-form-field>
                <input matInput placeholder="Anti-Coagulant" formControlName="antiCoagulant"
                    [ngClass]="{ 'is-invalid': analysisFormGroup.controls['antiCoagulant'].errors }">
                <mat-error *ngIf="analysisFormGroup.controls['antiCoagulant'].errors" class="invalid-feedback error">
                    <div *ngIf="analysisFormGroup.controls['antiCoagulant'].errors.pattern">
                        Must be <strong>Alphanumeric</strong>
                    </div>
                </mat-error>
            </mat-form-field>

        </div>
        <div class="col-md-6 my-1">

            <mat-form-field>
                <input matInput placeholder="Species" formControlName="species"
                    [ngClass]="{ 'is-invalid': analysisFormGroup.controls['species'].errors }">
                <mat-error *ngIf="analysisFormGroup.controls['species'].errors" class="invalid-feedback error">
                    <div *ngIf="analysisFormGroup.controls['species'].errors.pattern">
                        Only <strong>Alphabets</strong>
                    </div>
                </mat-error>
            </mat-form-field>

        </div>
        <div class="col-md-6 my-1">
            <mat-form-field>
                <mat-select placeholder="Sample Preparation" formControlName="samplePrep" disableOptionCentering>
                    <mat-option *ngFor="let obj of getDropDownValues('samplePrep')" [value]="obj.name">
                        {{obj.desc}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-6 my-1">
            <mat-form-field>
                <input matInput [matDatepicker]="picker" [max]="maxDate" placeholder="Choose a date"
                    formControlName="sampleDate"
                    [ngClass]="{ 'is-invalid': analysisFormGroup.controls['sampleDate'].errors }">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>

                <mat-error *ngIf="analysisFormGroup.controls['sampleDate'].errors" class="invalid-feedback-error">
                    <div *ngIf="analysisFormGroup.controls['sampleDate'].errors.max">
                        You cannot set <strong>Future Date</strong>
                    </div>
                </mat-error>
            </mat-form-field>
        </div>
    </div>




    <div class="row">
        <div class="col-md-9 my-1">
            <div class="col-md-12">
                <mat-radio-group style="margin-top: 10px;">
                    <label>Select Type of Acceptance Criteria Threshold</label>
                    &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <mat-radio-button value="Yes" (click)="changecustomlayout()">Custom</mat-radio-button>
                    <mat-radio-button value="No" (click)="changedefaultlayout()"
                        [checked]="customdisable">Default</mat-radio-button>
                </mat-radio-group>
            </div>
            <br>
            <div *ngIf="!customdisable">
                <mat-label> Enter Customized Thresholds: </mat-label>
                <hr>
                <div *ngIf="analysistype=='SMR'">
                    <div *ngFor="let table of Smparameterlist; let i=index" class="table-display">
                        <label>{{table.parameter_label}}:</label>
                        <input required class="inputbox" [(ngModel)]="table.parameter_value"
                            (change)="Smallvaluechange($event,i)" [ngModelOptions]="{standalone: true}"
                            size="4">{{table.parameter_value}}
                    </div>
                </div>

                <div *ngIf="analysistype=='LMR'">
                    <div *ngFor="let table of Lmparameterlist; let i=index" class="table-display">
                        <label>{{table.parameter_label}}:</label>
                        <input required class="inputbox" [(ngModel)]="table.parameter_value"
                            (change)="Largevaluechange($event,i)" [ngModelOptions]="{standalone: true}"
                            size="4">{{table.parameter_value}}
                    </div>
                </div>
            </div>
            <hr>
            <mat-label> General Tables </mat-label>
            <hr>
            <div>
                <mat-selection-list (selectionChange)="selectedGeneralTables($event)" >

                    <mat-list-option *ngFor="let table of GenTablesList" [value]="table.table_name"
                        [selected]="table.checked" >
                        {{table.table_name}}
                    </mat-list-option>
                </mat-selection-list>

            </div>

            <br>

            <mat-label> Sample Analysis Tables </mat-label>
            <hr>
            <div>

                <mat-selection-list (selectionChange)="selectedSampleTables($event)" >

                    <mat-list-option *ngFor="let table of SumTablesList" [value]="table.table_name"
                        [selected]="table.checked" >
                        {{table.table_name}}
                    </mat-list-option>
                </mat-selection-list>
            </div>

            <br>
            <mat-label> Validation Tables </mat-label>
            <hr>
            <div>
                <mat-selection-list (selectionChange)="selectedValidTables($event)" >

                    <mat-list-option *ngFor="let table of ValTablesList" [value]="table.table_name"
                        [selected]="table.checked">
                        {{table.table_name}}
                    </mat-list-option>
                </mat-selection-list>
            </div>


        </div>
    </div>


    <div>
        <button class="btn btn-primary m-1 my-5" color="primary" type="submit"
            [disabled]="((!analysisFormGroup.valid) || (analysisFormGroup.controls['multipleAnalytes'].value === 'Yes' && analysisFormGroup.controls['analyteNames'].value.length == 1))"
            matStepperNext>Submit Form
        </button>

    </div>
</form>