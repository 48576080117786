<app-header></app-header>

<ng-container *ngIf="!mfaStep && !firstTimePassword">
  <div class="row justify-content-center my-5">
    <div class="col-11 col-sm-5 col-md-5 col-lg-4 col-xl-3 m-auto">
      <form [formGroup]="signinForm" (ngSubmit)="signin()" novalidate>
        <h2 class="form-signin-heading text-capitalize text-center mb-2">
          Please sign in
        </h2>
        <br />
        <div class="form-group">
          <mat-form-field appearance="legacy" style="font-size: 15px">
            <mat-label>User Name</mat-label>
            <input type="text" matInput formControlName="username" placeholder="User Name" name="username"
              [ngClass]="{ 'is-invalid': submitted && formControls.username.errors }" />
            <mat-error *ngIf="formControls.username.errors" class="invalid-feedback error">
              <div *ngIf="formControls.username.errors.required">
                Username is <strong>required</strong>
              </div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field appearance="legacy" style="font-size: 15px">
            <mat-label>Password</mat-label>
            <input type="password" matInput formControlName="password" placeholder="Password" name="password"
              [ngClass]="{ 'is-invalid': submitted && formControls.password.errors }"
              [type]="hide ? 'password': 'text' " />
            <mat-icon matSuffix (click)="hide = !hide" style="cursor: pointer">{{hide ? 'visibility_off' :
              'visibility'}}
            </mat-icon>
            <mat-error *ngIf="formControls.password.errors" class="invalid-feedback error">
              <div *ngIf="formControls.password.errors.required">
                Password is <strong>required</strong>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <div style="height: 3rem">
          <div *ngIf="statusCode" class="text-center element-center">
            <div class="alert text-danger">{{ submissionError }}</div>
          </div>
        </div>

        <div class="text-center">
          <a [routerLink]="['/extra-layout/forgot-password']">Forgot Password?</a>
        </div>

        <div class="form-group my-5 text-center element-center">
          <button [disabled]="!signinForm.valid || submitted" mat-raised-button color="accent" type="submit"
            style="font-size: 16px">
            Sign-in
            <i class="fa fa-lock" aria-hidden="true"></i>
          </button>
        </div>
      </form>

      <div style="height: 6rem">
        <app-loading *ngIf="submitted"></app-loading>
      </div>
    </div>
  </div>
</ng-container>

<div class="my-lg-5">
  <div class="row">
    <div class="form-group col-lg-4 text-center element-center m-auto" *ngIf="(mfaStep && !firstTimePassword)">
      <awscognito-mfa class="element-center" [destination]="mfaData.destination" [onSubmit]="mfaData.callback">
      </awscognito-mfa>
      <div class="alert text-danger element-center">
        {{ submissionMFAError }}
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="firstTimePassword">
  <div class="row password justify-content-center">
    <div class="col-11 col-sm-5 col-md-5 col-lg-4 col-xl-3 m-auto">
      <form [formGroup]="firstTimePasswordForm" (ngSubmit)="updatePassword()" method="post" novalidate>
        <h2 class="text-capitalize">Enter New Password</h2>
        <p>First time signed in users must update their password.</p>
        <div class="form-group">
          <mat-form-field appearance="legacy" style="font-size: 15px">
            <mat-label>Password</mat-label>
            <input type="password" matInput formControlName="password" placeholder="Password" name="password"
              [ngClass]="{ 'is-invalid': submitted && newUserFormControls.password.errors }" />
            <mat-error *ngIf="newUserFormControls.password.errors" class="invalid-feedback error">
              <div *ngIf="newUserFormControls.password.errors.required">
                Password is <strong>required</strong>
              </div>
              <div *ngIf="newUserFormControls.password.errors.pattern">
                Password should be at least 8 characters including 1 number, 1 uppercase, 1 lowercase character and 1 special character.
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field appearance="legacy" style="font-size: 15px" class="my-5">
            <mat-label>Confirm Password</mat-label>
            <input type="password" matInput formControlName="confirmPassword" placeholder="Confirm Password"
              name="confirmPassword"
              [ngClass]="{ 'is-invalid': submitted && newUserFormControls.confirmPassword.errors }" />
            <mat-error *ngIf="newUserFormControls.confirmPassword.errors" class="invalid-feedback error">
              <div *ngIf="newUserFormControls.confirmPassword.errors.required">
                Confirm Password is <strong>required</strong>
              </div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <input type="checkbox" formControlName="disclaimer"
            [ngClass]="{ 'is-invalid': submitted && newUserFormControls.disclaimer.errors}" />
          <span>
            By checking this box, I acknowledge that I have read and agree to
            these
            <a href="/assets/data/EULA.pdf" target="_blank">
              Terms of Service and EULA.
            </a>
          </span>
          <div *ngIf="newUserFormControls.disclaimer.errors" class="invalid-feedback error">
            <div *ngIf="newUserFormControls.disclaimer.errors.required">Select the checkbox</div>
          </div>

        </div>

        <div class="form-group my-5">
          <button [disabled]="!firstTimePasswordForm.valid || submitted"
            mat-raised-button color="accent" type="submit" style="font-size: 16px">
            <i class="fa fa-lock" aria-hidden="true"></i>
            Update Password
          </button>
        </div>
        <div *ngIf="!isValid" class="alert text-danger error">
          {{ submissionError }}
        </div>
        <div *ngIf="infoMessage">
          <p class="text-success">{{ infoMessage }}</p>
        </div>
      </form>
      <app-loading *ngIf="submitted"></app-loading>
    </div>
  </div>
</ng-container>

<app-footer class="fixed-bottom"></app-footer>