import { Component, OnDestroy, OnInit } from '@angular/core';
import {SharedService} from '../../layouts/shared-service';
import {Router} from '@angular/router';
import 'rxjs/Rx';

import {DBService} from '../../service';
import {DownLoadService} from '../download';
import {AuthService} from '../../service';
import {User} from '../../model/types';
import {RDSAnalysisData} from '../../model/analysis-details.model';
import {DropDownService} from '../../service/drop-down.service';
import {AppLoaderService} from '../../service/app-loader/app-loader.service'

@Component({
    selector: 'app-previous-analysis',
    templateUrl: './previous-analysis.component.html',
    styleUrls: ['./previous-analysis.component.scss']
})
export class PreviousAnalysisComponent implements OnInit,  OnDestroy {

    pageTitle = 'Previous Activities';
    signedInUser: any;
    // previousAnalysisKeys: AnalysisKey[] = [];
    previousAnalysisKeys: RDSAnalysisData[] = [];
    pageLoaded = false;


    constructor(private _sharedService: SharedService,
                private authService: AuthService,
                private router: Router,
                private dbService: DBService,
                private dropDownService: DropDownService,
                private downloadService: DownLoadService,
                private loader: AppLoaderService, ) {
        this._sharedService.emitChange(this.pageTitle);

    }

    openDialog() {
        this.loader.open();
      }


    // ngOnInit(): void {

        // this.authService.getCurrentUser((err, user: User) => {
        //     this.signedInUser = user;
        //     if (!this.signedInUser || !this.signedInUser.signedIn) {
        //         this.router.navigate(['/extra-layout/signin']);
        //         return;
        //     } else {
        //         this.dbService.getUserAnalysis(this.signedInUser.username);
        //     }
        // });

        // this.dbService.userSearch.subscribe(
        //     (data: PreviousAnalysis[]) => {
        //         const util = new Utils(this.dropDownService.nameDescMap)
        //         this.previousAnalysisKeys = util.restructureSearch(data);
        //   
        //         this.pageLoaded = true;
        //         // if(this.pageLoaded==true){
        //         //     this.closeDialog()
        //         //   }
        //     }
        // );



    //     this.dbService.rdsAnalysisData.subscribe(
    //         (data: RDSAnalysisData[]) => {
    //             this.previousAnalysisKeys = data;
    //             this.pageLoaded = true;
    //            
    //     );


    // }



    ngOnInit(): void {

        this.authService.getCurrentUser((err, user: User) => {
            this.signedInUser = user;
            console.log('User in Previous Analysis Page is: ', user)
            if (!this.signedInUser || !this.signedInUser.signedIn) {
                this.router.navigate(['/extra-layout/signin']);
                return;
            } else {
                this.dbService.getAnalysis('PI');
            // this.dbService.getUserAnalysis(this.signedInUser.username);
            }
        });

        this.dbService.rdsAnalysisData.subscribe(
            (data: RDSAnalysisData[]) => {
                console.log('Previous Analysis data from backend ', data);
                // const util = new Utils(this.dropDownService.nameDescMap)
                this.previousAnalysisKeys = data;
                this.pageLoaded = true;
                console.log(this.previousAnalysisKeys)
                // if(this.pageLoaded==true){
                //     this.closeDialog()
                //   }
            }
        );


    }


    closeDialog() {
        this.loader.close();
      }




    ngOnDestroy(): void {
    }

}
