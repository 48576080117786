<app-header></app-header>
<div class="row password justify-content-center">
  <div class="col-lg-4 element-center">
    <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()" method="post" novalidate>
      <h2 class="text-capitalize">Enter New Password</h2>
      <p>Please enter the verification code sent to your email.</p>
      <p *ngIf="statusCode === 'success' " class="alert text-success" role="alert">
        {{statusMessage}}
      </p>

      <div class="form-group">
        <mat-form-field appearance="legacy" style="font-size: 15px">
          <mat-label>Verification Code</mat-label>
          <input type="password" matInput formControlName="verificationCode" placeholder="Verification Code"
            name="verificationCode" [ngClass]="{ 'is-invalid': submitted && formControls.verificationCode.errors }">
          <mat-error *ngIf="formControls.verificationCode.errors" class="invalid-feedback error">
            <div *ngIf="formControls.verificationCode.errors.required">Verification Code is <strong>required</strong>
            </div>
          </mat-error>
        </mat-form-field>
      </div>


      <div class="form-group">
        <mat-form-field appearance="legacy" style="font-size: 15px">
          <mat-label>New Password</mat-label>
          <input type="password" matInput formControlName="password" placeholder="Password" name="password"
            [ngClass]="{ 'is-invalid': submitted && formControls.password.errors }" [type]="hide ? 'password': 'text' ">
          <mat-icon matSuffix (click)="hide = !hide" style="cursor: pointer;">{{hide ? 'visibility_off' : 'visibility'}}
          </mat-icon>
          <mat-error *ngIf="formControls.password.errors" class="invalid-feedback error">
            <div *ngIf="formControls.password.errors.required">New Password is <strong>required</strong></div>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-group">
        <mat-form-field appearance="legacy" style="font-size: 15px">
          <mat-label>Confirm New Password</mat-label>
          <input type="password" matInput formControlName="confirmPassword" placeholder="Confirm Password"
            name="confirm password" [ngClass]="{ 'is-invalid': submitted && formControls.confirmPassword.errors }">
          <mat-error *ngIf="formControls.confirmPassword.errors" class="invalid-feedback error">
            <div *ngIf="formControls.confirmPassword.errors.required">Confirm New Password is <strong>required</strong>
            </div>
          </mat-error>
        </mat-form-field>
      </div>


      <div *ngIf="statusCode === 'unknownError' || !isValid && !isSuccessful">
        <div class="alert text-danger">{{ submissionError }}</div>
      </div>

      <div *ngIf="statusCode === 'success' && isSuccessful">

      </div>

      <div class="form-group">
        <button mat-raised-button color="accent" type="submit" style="font-size: 16px;"
          [disabled]="!resetPasswordForm.valid || submitted">
          <i class="fa fa-lock" aria-hidden="true"></i>
          Update Password
        </button>
      </div>
    </form>
    <app-loading *ngIf="submitted"></app-loading>
  </div>
</div>
<app-footer class="fixed-bottom"></app-footer>
