<card *ngIf="reportAnalysis.length > 0" [title]="'Report Analysis'" class="mb-0">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
  </mat-form-field>

      <!-- Pdf download button -->

  <div class="col-md-4 text-right">
    <button class="btn btn-success btn-block" (click)="openPDF1()">Download PDF</button>
</div>







  <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort    id="htmlData1">
    <ng-container matColumnDef="analyteName">
      <mat-header-cell *matHeaderCellDef> Analyte Name </mat-header-cell>>
      <mat-cell  *matCellDef="let element"><a [routerLink]="[getUrl(element.analysisType), element.analysisId, element.userId, {type: 'previous'}]">{{element.file_name
      }} </a></mat-cell>
    </ng-container>
 
    <ng-container matColumnDef="analysisDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Analysis Date </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.analysis_date
      }} </mat-cell>
    </ng-container>
 
    <ng-container matColumnDef="projectNames">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Project </mat-header-cell>
      <mat-cell *matCellDef="let element"><a [routerLink]="[getUrl(element.analysisType), element.analysisId, element.userId]">{{element.project_code}}</a></mat-cell>
    </ng-container>
    <ng-container matColumnDef="analysisType">
      <mat-header-cell *matHeaderCellDef> Analysis Type </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.analysis_type}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="analysisSubtypeDisplay">
      <mat-header-cell *matHeaderCellDef> Analysis Sub-Type </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.analysis_subtype}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: tableColumns;"></mat-row>
  </mat-table>
  <mat-paginator #RApaginator [length]="reportAnalysis.length" [pageSizeOptions]="[ 10, 20]"  showFirstLastButtons></mat-paginator>
</card>


<card *ngIf="reportPopulation.length > 0" [title]="'Report Population Analysis'"  class="mb-0 my-5">

  <mat-form-field>
    <input matInput (keyup)="applyFilterRSG($event.target.value)" placeholder="Filter">
  </mat-form-field>


<!-- Pdf download button -->

  <div class="col-md-4 text-right">
    <button class="btn btn-success btn-block" (click)="openPDF2()">Download PDF</button>
</div>






  <mat-table [dataSource]="reportPopulationDS" class="mat-elevation-z8" #RSGsort="matSort" matSort    id="htmlData2"  >

    &lt;!&ndash;- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" &ndash;&gt;

    <ng-container matColumnDef="analyteName">
      <mat-header-cell *matHeaderCellDef> Study Name </mat-header-cell>>
      <mat-cell  *matCellDef="let element"><a [routerLink]="[getUrl(element.analysisType), element.analysisId, element.userId]">{{element.analyteNames}} </a></mat-cell>
    </ng-container>

    <ng-container matColumnDef="analysisDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Analysis Date </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.analysisDate}}</mat-cell>
    </ng-container>


    <ng-container matColumnDef="analysisType">
      <mat-header-cell *matHeaderCellDef> Analysis Type </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.analysisTypeDisplay}} </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="reportPopulationColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: reportPopulationColumns;"></mat-row>
  </mat-table>
  <mat-paginator #RSGpaginator [length]="reportPopulation.length" [pageSizeOptions]="[ 10, 20]"  showFirstLastButtons></mat-paginator>
</card>



<card *ngIf="chromatogramAnalysis.length > 0" [title]="'Chromatogram Processing'"  class="mb-0 my-5">

  <mat-form-field>
    <input matInput (keyup)="applyFilterCH($event.target.value)" placeholder="Filter">
  </mat-form-field>



<!-- Pdf download button -->
  <div class="col-md-4 text-right">
    <button class="btn btn-success btn-block" (click)="openPDF3()">Download PDF</button>
</div>






  <mat-table [dataSource]="chromatogramDS" class="mat-elevation-z8" #CHsort="matSort" matSort       id="htmlData3">

    &lt;!&ndash;- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" &ndash;&gt;

    <ng-container matColumnDef="analyteName">
      <mat-header-cell *matHeaderCellDef> Study Name </mat-header-cell>>
      <mat-cell  *matCellDef="let element"><a [routerLink]="[getUrl(element.analysisType), element.analysisId, element.userId]">{{element.analyteNames}} </a></mat-cell>
    </ng-container>

    <ng-container matColumnDef="analysisDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Analysis Date </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.analysisDate}}</mat-cell>
    </ng-container>


    <ng-container matColumnDef="analysisType">
      <mat-header-cell *matHeaderCellDef> Analysis Type </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.analysisTypeDisplay}} </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="chromatogramColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: chromatogramColumns;"></mat-row>
  </mat-table>
  <mat-paginator #CHpaginator [pageSizeOptions]="[ 10, 20]"  showFirstLastButtons></mat-paginator>
</card>