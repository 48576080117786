<div *ngIf="!finish">

    <br>


    <mat-accordion class="example-headers-align">
        <mat-expansion-panel *ngFor="let upload of analyte.uploadObjs" [expanded]="step === upload.index"
        hideToggle>

            <mat-expansion-panel-header>
                <mat-panel-title>
                    <b class="mb-0">{{upload.uploadType}}</b>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row">
                <div class="col-lg-11">
                    <div *ngFor="let localAnalyte of analysisDetails.analytes">
                        <div *ngIf="localAnalyte.analyteName === analyte.analyteName">
                            <div *ngFor="let localUpload of localAnalyte.uploadObjs">
                                <div *ngIf="localUpload.uploadType === upload.uploadType">
                                    <card *ngIf="localUpload.files.length > 0">

                                        <strong>Uploaded Files</strong>

                                        <ol>
                                            <div *ngFor="let localFile of localUpload.files">
                                                <li>{{localFile.name}}</li>
                                            </div>
                                        </ol>
                                        <!-- <div>
                                        <div *ngIf="step == analyte.uploadObjs.length-1">
                                            <button mat-raised-button color="accent" (click)="submit()">Submit</button>
                                        </div>
                                        <div *ngIf="step != analyte.uploadObjs.length-1">
                                            <button mat-raised-button color="primary" (click)="nextStep()">Next</button>
                                        </div>
                                    </div> -->
                                        <br>
                                    </card>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="files previews">
                        <!-- <div *ngFor="let upload of analyte.uploadObjs;">
                            <app-file-upload *ngFor="let file of upload.files; let oddRow=odd;" [oddRow]="oddRow"
                                [fileObject]="file"></app-file-upload>
                        </div> -->


                        <div *ngFor="let upload of analyte.uploadObjs; let uploadObjsindex=index  ">
                            <app-file-upload *ngFor="let file of upload.files; let oddRow=odd;let fileuploadindex=index"
                                [oddRow]="oddRow" (deleteIndexEmitter)="getdeleteIndex(uploadObjsindex,fileuploadindex)"
                                [fileObject]="file"  [disablefileupload]="disablefileupload">
                            </app-file-upload>
                        </div>
                        <div class="alert alert-danger"
                        *ngIf="!verificationStatus">
                       {{verificationMessage}}
                   </div>

                        <br />
                    </div>

                    <div style="width: 92px;float: left;">
                        <div class="divLabel" [style.background-color]="colorLabel">
                            &nbsp;
                            &nbsp;
                            <label style="margin-top: 7px; cursor: pointer;">Add files
                                <input type="file" accept=".xlsx, .xls, .docx, .pdf, .csv" hidden name="files[]"
                                    multiple (change)="fileChangeEvent($event, upload.uploadType)"
                                    [disabled]="disableAdd">
                            </label>
                            &nbsp;
                            &nbsp;
                        </div>
                    </div>
                    <div style="float: left; width: 100px;">
                        <button type="button" class="btn btn-success" [disabled]="uploadDisabled"
                            [disabled]="!upload.files.length" (click)="uploadAll(upload.uploadType)">Upload all
                        </button>
                    </div>
                    <!-- <div *ngIf="step != analyte.uploadObjs.length-1"  style="float: left;">
                    <button class="btn btn-dark" (click)="nextStep()">Next</button>
                </div> -->

                    <div style="float: left; width: 100px;">
                        <button class="btn btn-primary" (click)="submit()" [disabled]="disabledValue">Submit</button>
                    </div>
                    <!-- <button type="button" class="btn btn-danger" color="warn" (click)="clearAll()"
                        [disabled]="!upload.files.length">Remove all</button> -->
                </div>

                <!-- <div class="col-md-3 my-5">
                    <p class="font-weight-bold  mx-2">Customize Tables</p>
                    <button class="btn btn-info" (click)="openDialog()">Advance Features</button>
                </div> -->
            </div>
        </mat-expansion-panel>
    </mat-accordion>


</div>


<card *ngIf="finish">

    <app-analysis-details></app-analysis-details>
</card>