<card class="mb-0">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
  </mat-form-field>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort matSortStart="desc">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <ng-container matColumnDef="userId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> User ID </th>
      <td mat-cell *matCellDef="let element">{{element.userId}}
    </ng-container>

    <ng-container matColumnDef="activityType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Activity Type </th>
      <td mat-cell *matCellDef="let element"> {{element.activityType}}</td>
    </ng-container>

    <ng-container matColumnDef="activityDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Activity Date </th>
      <td mat-cell *matCellDef="let element">{{element.activityDate}}
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[ 10, 20]" showFirstLastButtons></mat-paginator>
</card>



