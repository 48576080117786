<app-header></app-header>
<div class="row password justify-content-center mt-xl-5">
  <div class="col-lg-4 element-center">
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()" method="post" novalidate>
      <div *ngIf="name else default">
        <h2 class="text-capitalize">Reset Password?</h2>
      </div>
      <ng-template #default>
        <h2 class="text-capitalize">Forgot Password?</h2>
      </ng-template>
      
      <p>You can reset your password here.</p>
      <div class="form-group">
        <mat-form-field appearance="legacy" style="font-size: 15px;" class="error">
          <mat-label>User Name</mat-label>
          <input type="text" matInput formControlName="username" placeholder="Username" name="username"
            [ngClass]="{ 'is-invalid': submitted && formControls.username.errors }">
          <mat-error *ngIf="formControls.username.errors" class="invalid-feedback error">
            <div *ngIf="formControls.username.errors.required">Username is <strong>required</strong></div>
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="statusCode === 'noSuchUser' || statusCode === 'unknownError' " class="alert text-danger">
        {{ submissionError }}
      </div>
      <div class="form-group my-5">
        <button [disabled]="!forgotPasswordForm.valid || submitted" mat-raised-button color="accent" type="submit"
          style="font-size: 16px;">
          <i class="fa fa-lock" aria-hidden="true"></i>
          Reset Password
        </button>
      </div>
    </form>
    <app-loading *ngIf="submitted"></app-loading>
  </div>
</div>
<app-footer class="fixed-bottom"></app-footer>
