<card class="mb-0">
  <div class="container">
    <div class="col-md-6 offset-md-3">
      <h3>Verification</h3>

      <div *ngIf="model">
        <card [bgColor]="'success'" [color]="'success'" [outline]="true">
          <p><strong> Thank you for submitting your user information. You will receive an email with a verification
              code. Please enter that verification code below to complete your account sign-up process.</strong></p>
        </card>
        <br>
        <div class="row">
          <div class="col-md-12"><strong>User Name:</strong>
            {{model.username}}</div>
          <div class="col-md-12"><strong>Name:</strong>
            {{model.name}}</div>
          <div class="col-md-12"><strong> Email Id</strong> {{model.email}}</div>
          <div class="col-md-12"><strong>Phone</strong> {{model.phone}}</div>
        </div>
      </div>
      <br>
      <form [formGroup]="verificationForm" (ngSubmit)="onConfirm()" class="mb-5">
        <div class="form-group">
          <mat-form-field appearance="legacy">
            <mat-label>User Name</mat-label>
            <input type="text" matInput formControlName="user_name" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && form.user_name.errors }" />
            <mat-error *ngIf="submitted && form.user_name.errors" class="invalid-feedback">
              <div *ngIf="form.user_name.errors.required">User Name is required</div>
            </mat-error>
          </mat-form-field>

        </div>

        <div class="form-group">
          <mat-form-field appearance="legacy">
            <mat-label>Verification Code</mat-label>
            <input type="text" matInput formControlName="verificationCode" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && form.verificationCode.errors }" />
            <mat-error *ngIf="submitted && form.verificationCode.errors" class="invalid-feedback">
              <div *ngIf="form.verificationCode.errors.required">Verification Code is required</div>
            </mat-error>
          </mat-form-field>
        </div>
        <button mat-raised-button color="accent" [disabled]="verificationForm.invalid" class="my-5">Confirm your
          Account</button>
      </form>
    </div>
  </div>
</card>