<nav class="main-menu">
  <ul>
    <li
      class="menu-item"
      *ngFor="let item of mainMenuItems"
      [ngClass]="{
        'has-sub': item.sub,
        'active': item.active,
        'menu-item-group': item.groupTitle
      }"
      routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
    >
      <!-- Group title -->
      <span class="group-title" *ngIf="item.groupTitle">{{ item.title }}</span>

      <!-- Simple item -->
      <a href="#" routerLink="{{ item.routing }}" class="item-link" *ngIf="!item.groupTitle && !item.sub">
        <span class="item-badge" *ngIf="item.budge" [ngStyle]="{'background': item.budgeColor}">{{ item.budge }}</span>
        <span class="icon" *ngIf="item.icon" [ngClass]="item.icon"></span>{{ item.title }}
      </a>

      <!-- Item with sub -->
      <a href="#" class="item-link" *ngIf="!item.groupTitle && item.sub" (click)="toggle($event, item, this)">
        <span class="item-badge" *ngIf="item.budge" [ngStyle]="{'background': item.budgeColor}">{{ item.budge }}</span>
        <span class="icon" *ngIf="item.icon" [ngClass]="item.icon"></span>{{ item.title }}
        <i class="caret fa fa-angle-right" *ngIf="item.sub"></i>
      </a>

      <!-- Menu sub -->
      <ul class="sub" *ngIf="!item.groupTitle && item.sub">
        <li
          class="menu-item"
          *ngFor="let itemSub of item.sub"
          routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
        >
          <a href="#" routerLink="{{ itemSub.routing }}" class="item-link">{{ itemSub.title }}</a>
        </li>
      </ul>
    </li>
  </ul>
</nav>