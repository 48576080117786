<div [ngClass]="{'file-row': true, row: true, odd: oddRow}">
  <div class="col-lg-6">
    <span class="name">{{fileObject.file.name}}
      <div *ngIf="disablefileupload">
        <i class="fa fa-remove" style="font-size:15px;" (click)="deleteFile()"></i>
      </div>
    </span>


    <span style="float:right;">
      <span [ngClass]="{'text-primary': fileObject.status === FileObjectStatus.Uploading, 'text-success': fileObject.status === FileObjectStatus.Uploaded,
       'text-danger': fileObject.status === FileObjectStatus.Failed}">
        <strong *ngIf="fileObject.status !== FileObjectStatus.NotStarted"
          [ngClass]="{'error': fileObject.status === FileObjectStatus.Failed}">
          {{FileObjectStatus[fileObject.status]}}
        </strong>
      </span>
    </span>
    <strong class="error text-danger">{{uploadError}}</strong>
  </div>
  <div class="col-lg-6">


  </div>
</div>