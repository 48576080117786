import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {AnalysisKey, RDSAnalysisData} from '../../../model/analysis-details.model';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  // dataSource: MatTableDataSource<AnalysisKey>;
  // tableColumns: string[] = ['userId', 'analysisType', 'analysisSubtype', 'analysisDate',  'projectNames', 'analyteName'];


  dataSource: MatTableDataSource<RDSAnalysisData>;
  tableColumns: string[] = ['projectCode', 'fileName', 'analysisDate', 'status' , 'userId'];
  @ViewChild(MatPaginator, {static: false}) ch_paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) ch_sort: MatSort;
  @Input() searchResults: RDSAnalysisData[] = [];

  constructor() {
  }

  ngOnInit() {
    // console.log('Constructor ', this.searchResults);
    this.dataSource = new MatTableDataSource(this.searchResults);
    setTimeout(() => this.dataSource.paginator = this.paginator);
    setTimeout(() => this.dataSource.sort = this.sort);

  }

  getUrl(analysisType: string) {
    if (analysisType === 'RSG') {
      return '/default-layout/analysis-details';
    } else if (analysisType === 'SMR' || analysisType === 'LMR' || analysisType === 'ADA') {
      return '/default-layout/molecule-analysis';
    } else {
      return '/default-layout/notfound';
    }
  }



  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openPDF() {

  }
}
