// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  serverUrl: 'https://kgqufa2374.execute-api.us-east-2.amazonaws.com/dev',
  bucket: 'shailesh-dev-upload',
  userPool: {
    UserPoolId: 'us-east-2_y1nBHMbof',
    ClientId: '1234qu5tika0b1ks5a70j4bf92',
    region: 'us-east-2'
  },
  identityPoolId: 'us-east-2:b0c4ea0f-9139-44cf-9563-ff0d6cc55fbd',
  buckets: {
    'us-east-2': 'shailesh-dev-upload'
  },
  defaultRegion: 'us-east-2',
  cognito: {
    identityPoolId: 'us-east-2:b0c4ea0f-9139-44cf-9563-ff0d6cc55fbd', // REQUIRED - Amazon Cognito Identity Pool ID
    region: 'us-east-2', // REQUIRED - Amazon Cognito Region
    userPoolId: 'us-east-2_y1nBHMbof', // OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: '1234qu5tika0b1ks5a70j4bf92', // OPTIONAL - Amazon Cognito Web Client ID
  },
  Storage: {
    AWSS3: {
      bucket: 'shailesh-dev-upload', // REQUIRED -  Amazon S3 bucket
      region: 'us-east-2', // OPTIONAL -  Amazon service region
    }
  }
};
const awsconfig = {
  'aws_project_region': 'us-east-2',
  'aws_cognito_region': 'us-east-2',
  'aws_user_pools_id': 'us-east-2_y1nBHMbof',
  'aws_user_pools_web_client_id': '1234qu5tika0b1ks5a70j4bf92',
  'aws_cognito_mfa_configuration': 'OPTIONAL',

};
export default awsconfig;

