import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../layouts/shared-service';
import { AuthService, DashboardService, DBService } from '../../service';
import { Router } from '@angular/router';
import { AnalysisSummary, Summary } from '../../model/analysis-details.model';
import { User } from '../../model/types';
import { RTDashBoard } from '../../model/dashboard.model';
import { AppLoaderService } from '../../service/app-loader/app-loader.service';
import { AuthNewService } from '../../service/auth-new.service';
import { delay } from 'rxjs/operators';

@Component({
  moduleId: module.id,
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})


export class DashboardComponent implements OnInit {
  pageTitle = 'Dashboard';
  signedInUser: any;
  loadPage = false;
  temp: any;

  title = 'Loading';

  public dashboard_data: RTDashBoard[] = [];
  public analysisSummary: AnalysisSummary[];
  public summary: Summary;
  public dateArray: [];
  public countArray: [];

  constructor(private _sharedService: SharedService,
    private authService: AuthService,
    private router: Router,
    private dbService: DBService,
    private dashboardService: DashboardService,
    private newAuthService: AuthNewService,
    private loader: AppLoaderService
  ) {
    // if(this.loadPage == false){
    //   this.openDialog()
    // }
    this.temp = JSON.parse(sessionStorage.getItem('xyz'))
    this._sharedService.emitChange(this.pageTitle);

    // this.authService.getCurrentUser((err, user: User) => {
    //   this.signedInUser = user;
    //   if (!this.signedInUser || !this.signedInUser.signedIn) {
    //     this.router.navigate(['/extra-layout/signin']);
    //     return;
    //   } else {
    //     this.dbService.getSummary(this.signedInUser.username);
    //     if (this.temp == null) {
    //       this.dashboardService.getData(this.signedInUser.username);
    //     } else {
    //       this.dashboard_data = this.temp;
    //       this.loadPage = true;
    //     }

    //   }
    // });

  }

  ngOnInit() {



    this.newAuthService.getCurrentUser();
    this.newAuthService.getCurrentLoginUser.pipe(delay(1000)).subscribe((user: any) => {
      this.signedInUser = user;
      if (!this.signedInUser) {
          this.router.navigate(['/extra-layout/signin']);
          return;
      } else {
        this.dbService.getSummary(this.signedInUser.username);
        if (this.temp == null) {
          this.dashboardService.getData(this.signedInUser.username);
        } else {
          this.dashboard_data = this.temp;
          this.loadPage = true;
        }

      }
    });






    this.dashboardService.dash_board.subscribe((data: RTDashBoard[]) => {
      this.dashboard_data = data;
      // console.log(this.dashboard_data)
      this.loadPage = true;
      if (this.loadPage === true) {
        this.closeDialog();
      }
    });
  }

  openDialog() {
    this.loader.open(this.title);
    // setTimeout(() => {
    //   this.loader.close();
    // }, this.loadingTime)
    // if(this.loadPage==true){
    //   this.loader.close();
    // }
  }

  closeDialog() {
    this.loader.close();
  }

  private getTime(date?: Date) {
    return date != null ? date.getTime() : 0;
  }


  public sortByAnalysisDate(): void {
    this.analysisSummary.sort((a: AnalysisSummary, b: AnalysisSummary) => {

      // return a.date > b.date ? -1 : a.date < b.date ? 1 : 0;
      return this.getTime(new Date(b.date)) - this.getTime(new Date(a.date));
    });
  }


}
