<mat-horizontal-stepper orientation="horizontal" [linear]="isLinear" #stepper>
    <mat-step [stepControl]="analysisFormGroup">

        <ng-template matStepLabel>
            <div (click)="editform();">
                Fill out Analysis Details
            </div>
        </ng-template>

        <card class="mb-0">
            <div class="container" *ngIf="!post;">
                <form [formGroup]="analysisFormGroup" novalidate>

                    <div class="row">
                        <div class="col-md-9 my-1">
                            <mat-form-field>
                                <mat-select placeholder="Analysis Type" formControlName="analysisType"
                                    (ngModelChange)="resetAnalysis()" required disableOptionCentering>

                                    <mat-option *ngFor="let obj of getDropDownValues('analysisType')"
                                        [value]="obj.name">
                                        {{obj.desc}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div
                        *ngIf="analysisFormGroup.value.analysisType === 'SMR' || analysisFormGroup.value.analysisType === 'LMR' ">
                        <app-small-large-report [analysistype]="analysisFormGroup.value.analysisType" [editvalues]="editDetails"
                            (formvalue)=" displaydetails($event)"></app-small-large-report>
                    </div>
                    <div *ngIf="analysisFormGroup.value.analysisType == 'ADA'">
                        <app-ada-report [analysistype]="analysisFormGroup.value.analysisType" [editvalues]="editDetails"
                            (formvalue)=" displaydetails($event)"> </app-ada-report>
                    </div>
                   
                </form>
                <br>
                <br><br>
            </div>
        </card>
    </mat-step>


    <mat-step>
        <ng-template matStepLabel>
            <div *ngIf="!openSecondMatStep">
                Upload File
            </div>
            <div *ngIf="openSecondMatStep" (click)='editdetails(analysisDetails)'>
                Upload File
            </div>
        </ng-template>
     <ng-template [ngIf]=" post">

            <button class="btn btn-warning" style="float: right;" (click)="editform()" matStepperPrevious>Edit Analysis
                Details</button>
            <div class="row">
                <div class="col-md-6"><strong>Analysis Type:</strong>
                    {{getDisplayString(analysisDetails.analysisType)}}
                </div>

            </div>
            <br>
            <div *ngIf="analysisDetails.analysisType=='SMR' || analysisDetails.analysisType == 'LMR'">

                <div class="row">
                    <div class="col-md-6"><strong>Project Code:</strong> {{analysisDetails.projectCodeSA}}
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6"><strong>Selected Validation Tables:</strong>{{analysisDetails.selectedValTables}}
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6"><strong>Selected Summary Tables:</strong>{{analysisDetails.selectedSummaryTables}}
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6"><strong>Selected General Tables:</strong>{{analysisDetails.selectedGenralTables}}
                    </div>
                </div>


                <div class *ngIf="analysisDetails.analysisSubtype === 'SAM'" class="row">
                    <div class="col-md-6"><strong>Sample Analysis Project Code:</strong>
                        {{analysisDetails.projectCodeSA}}
                    </div>
                    <div class="col-md-6"><strong>Method Validation Project Code:</strong>
                        {{analysisDetails.projectCodeMV}}
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6"><strong>Analyte Name: </strong>{{analysisDetails.analyteNames}}</div>
                    <div class="col-md-6"><strong>ULOQ Value: </strong>{{analysisDetails.ULOQValue}}</div>
                    <div class="col-md-6"><strong>LLOQ Value: </strong>{{analysisDetails.LLOQValue}}</div>
                    <div class="col-md-6"><strong>Analysis Subtype:</strong>
                        {{getDisplayString(analysisDetails.analysisSubtype)}}</div>
                    <div class="col-md-6"><strong>Regression Model:</strong>
                        {{getDisplayString(analysisDetails.regressionModel)}}</div>
                    <div class="col-md-6"><strong>Long-Term Stability (Days):</strong> {{analysisDetails.lts80_dialog}}
                    </div>
                    <!--            <div class="col-md-6"><strong>LTS at -20C (Days):</strong> {{analysisDetails.lts20_dialog}}</div>-->
                    <div class="col-md-6"><strong>Extraction Stability (Hours):</strong>
                        {{analysisDetails.extraction_dialog}}</div>
                    <div class="col-md-6"><strong>Benchtop Stability (Hours):</strong>
                        {{analysisDetails.benchtop_dialog}}
                    </div>
                    <div class="col-md-6"><strong>Number of Freeze-Thaws Validated:</strong>
                        {{analysisDetails.freeze_thaw_dialog}}</div>
                    <div class="col-md-6"><strong>Matrix:</strong> {{analysisDetails.sampleMatrix}}</div>
                    <div class="col-md-6"><strong>Anti Coagulant:</strong>
                        {{analysisDetails.antiCoagulant}}</div>
                    <div class="col-md-6"><strong>Species:</strong> {{analysisDetails.species}}</div>
                    <div class="col-md-6"><strong>Date:</strong> {{analysisDetails.sampleDate | date: 'shortDate' }}
                    </div>
                    <div class="col-md-6"><strong>Sample Preparation:</strong>
                        {{getDisplayString(analysisDetails.samplePrep)}}</div>
                    <div class="col-md-6">
                        <strong class="d-inline-block">Multiple Analytes(Yes/No): </strong>
                        <p class="d-sm-inline-block mx-2" *ngIf="analysisDetails.multipleAnalytes === 'True'">Yes</p>
                        <p class="d-sm-inline-block mx-2" *ngIf="analysisDetails.multipleAnalytes === 'False'">No</p>

                    </div>
                    <!-- {{ analysisDetails.multipleAnalytes }} -->
                </div>
            </div>

                <div *ngIf="analysisDetails.analysisType=='ADA'">
                    <div class="row">

                        <div class="col-md-6"><strong>Analyte Name: </strong>{{analysisDetails.analyteNames}}</div>
                        <div class="col-md-6"><strong>Analyte Subtype: </strong>{{analysisDetails.analysisSubtype}}
                        </div>
                        <div class="col-md-6" *ngIf="analysisDetails.analysisSubtype == 'Sensitivity'">
                            <strong>Sensitivity Type:
                            </strong>{{analysisDetails.ada_subtype}}</div>

                        <div class="col-md-6"
                            *ngIf="analysisDetails.ada_subtype == 'Screening' && analysisDetails.analysisSubtype == 'Sensitivity'">
                            <strong>Screening Cutpoint: </strong>{{ analysisDetails.screening_cutpoint }}
                        </div>
                        <div class="col-md-6"
                            *ngIf="analysisDetails.ada_subtype == 'Confirmatory' && analysisDetails.analysisSubtype == 'Sensitivity'">
                            <strong>Confirmatory Cutpoint: </strong>{{ analysisDetails.confirmatory_cutpoint }}
                        </div>
                        <div class="col-md-6"
                            *ngIf="analysisDetails.ada_subtype == 'Screening & Confirmatory' && analysisDetails.analysisSubtype == 'Sensitivity'">
                            <strong>Screening Cutpoint: </strong>{{ analysisDetails.screening_cutpoint }}
                        </div>
                        <div class="col-md-6"
                            *ngIf="analysisDetails.ada_subtype == 'Screening & Confirmatory' && analysisDetails.analysisSubtype == 'Sensitivity'">
                            <strong>Confirmatory Cutpoint: </strong>{{ analysisDetails.confirmatory_cutpoint }}
                        </div>
                        <div class="col-md-6"
                            *ngIf="analysisDetails.analysisSubtype === 'Sensitivity' || analysisDetails.analysisSubtype === 'Cut Point & Sensitivity'">
                            <strong>Regression Type: </strong>
                            {{ analysisDetails.PL }}
                        </div>
                        <div class="col-md-6" *ngIf="analysisDetails.analysisSubtype == 'Cut Point'">
                            <strong>Cut Point Type: </strong>{{ analysisDetails.ada_subtype }}
                        </div>
                        <div class="col-md-6" *ngIf="analysisDetails.analysisSubtype == 'Cut Point & Sensitivity'">
                            <strong>Cut Point & Sensitivity Type: </strong>{{ analysisDetails.ada_subtype }}
                        </div>
                        <div class="col-md-6"><strong>Project Code:</strong> {{analysisDetails.projectCodeSA}}</div>
                        <div class="col-md-6"><strong>Tukey Coefficient: </strong>{{analysisDetails.tukeyCoefficient}}
                        </div>

                        <div class="col-md-6"><strong>Concentration Unit: </strong>{{ analysisDetails.concentrationUnit
                            }}</div>
                        <div class="col-md-6"><strong>NAB Dataset(Yes/No):
                            </strong>{{analysisDetails.nabDataset}}
                        </div>
                    </div>
                </div>

                <app-doc-upload [analysisDetails]="analysisDetails"></app-doc-upload>
        </ng-template>
    </mat-step>
</mat-horizontal-stepper>
