<card [title]="'Sign in with your User Id to continue'" [align]="'center'">
  <form (ngSubmit)="onSubmit()">
    <mat-form-field>
      <input matInput placeholder="Username" value="">
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="Password" value="">
    </mat-form-field>


    <button mat-raised-button color="primary" type="submit" color="accent">Sign In</button>

    <div class="additional-info">
      <a href="https://ariadnesolutions.com">Contact Administrator for Credentials</a>

    </div>
  </form>
</card>
