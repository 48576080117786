<app-header></app-header>
<div class="row password justify-content-center">
  <div class="col-lg-4 element-center">
    <form [formGroup]="firstTimeForm" (ngSubmit)="updatePassword()" method="post" novalidate>
      <h2 class="text-capitalize">Enter New Password</h2>
      <p>First time signed in users must update their password.</p>
      <p *ngIf="statusCode === 'signedIn'" class="alert text-success" role="alert">
        {{ statusMessage }}
      </p>
      <div class=form-group>
        <mat-form-field appearance="legacy" style="font-size: 15px;">
          <mat-label>Password</mat-label>
          <input type="password" matInput formControlName="password" placeholder="Password" name="password"
            [ngClass]="{ 'is-invalid': submitted && formControls.password.errors }">
          <mat-error *ngIf="formControls.password.errors" class="invalid-feedback error">
            <div *ngIf="formControls.password.errors.required">Password is <strong>required</strong></div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field appearance="legacy" style="font-size: 15px;">
          <mat-label>Confirm Password</mat-label>
          <input type="password" matInput formControlName="confirmPassword" placeholder="Confirm Password"
            name="confirmPassword" [ngClass]="{ 'is-invalid': submitted && formControls.confirmPassword.errors }">
          <mat-error *ngIf="formControls.confirmPassword.errors" class="invalid-feedback error">
            <div *ngIf="formControls.confirmPassword.errors.required">Confirm Password is <strong>required</strong>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="statusCode === 'incompletedSigninData' || statusCode === 'unknownError' || !isValid && !success"
        class="alert text-danger error">
        {{ submissionError }}
      </div>

      <div *ngIf="statusCode === 'signedIn' && success">

      </div>


      <div class="form-group my-5">
        <button [disabled]="!firstTimeForm.valid || submitted" mat-raised-button color="accent" type="submit"
          style="font-size: 16px;">
          <i class="fa fa-lock" aria-hidden="true"></i>
          Update Password
        </button>
      </div>
    </form>
    <app-loading *ngIf="submitted"></app-loading>
  </div>
</div>
<app-footer class="fixed-bottom"></app-footer>