<div class="row password justify-content-center">
    <div class="col-11 col-sm-5 col-md-5 col-lg-4 col-xl-3 m-auto">
      <form [formGroup]="firstTimePasswordForm" (ngSubmit)="updatePassword()" method="post" novalidate>
        <h2 class="text-capitalize">Enter New Password</h2>
        <p>First time signed in users must update their password.</p>
        <div class="form-group">
          <mat-form-field appearance="legacy" style="font-size: 15px">
            <mat-label>Password</mat-label>
            <input type="password" matInput formControlName="password" placeholder="Password" name="password"
              [ngClass]="{ 'is-invalid': submitted && newUserFormControls.password.errors }" />
            <mat-error *ngIf="newUserFormControls.password.errors" class="invalid-feedback error">
              <div *ngIf="newUserFormControls.password.errors.required">
                Password is <strong>required</strong>
              </div>
              <div *ngIf="newUserFormControls.password.errors.pattern">
                Password should be at least 8 characters including 1 number, 1 uppercase, 1 lowercase character and 1 special character.
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field appearance="legacy" style="font-size: 15px" class="my-5">
            <mat-label>Confirm Password</mat-label>
            <input type="password" matInput formControlName="confirmPassword" placeholder="Confirm Password"
              name="confirmPassword"
              [ngClass]="{ 'is-invalid': submitted && newUserFormControls.confirmPassword.errors }" />
            <mat-error *ngIf="newUserFormControls.confirmPassword.errors" class="invalid-feedback error">
              <div *ngIf="newUserFormControls.confirmPassword.errors.required">
                Confirm Password is <strong>required</strong>
              </div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <input type="checkbox" formControlName="disclaimer"
            [ngClass]="{ 'is-invalid': submitted && newUserFormControls.disclaimer.errors}" />
          <span>
            By checking this box, I acknowledge that I have read and agree to
            these
            <a href="/assets/data/EULA.pdf" target="_blank">
              Terms of Service and EULA.
            </a>
          </span>
          <div *ngIf="newUserFormControls.disclaimer.errors" class="invalid-feedback error">
            <div *ngIf="newUserFormControls.disclaimer.errors.required">Select the checkbox</div>
          </div>

        </div>

        <div class="form-group my-5">
          <button [disabled]="!firstTimePasswordForm.valid || submitted"
            mat-raised-button color="accent" type="submit" style="font-size: 16px">
            <i class="fa fa-lock" aria-hidden="true"></i>
            Update Password
          </button>
        </div>
        <div *ngIf="!isValid" class="alert text-danger error">
          {{ submissionError }}
        </div>
        <div *ngIf="infoMessage">
          <p class="text-success">{{ infoMessage }}</p>
        </div>
      </form>
      <app-loading *ngIf="submitted"></app-loading>
    </div>
  </div>
