import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { SharedService } from '../../layouts/shared-service';
import { AnalysisDetails, AnalysisDetailsCHR, DropDown, SearchResult, Templates } from '../../model/analysis-details.model';
import { DropDownService } from '../../service/drop-down.service';
import { AuthService, DBService } from '../../service';
import { User } from '../../model/types';
import { Router } from '@angular/router';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER, SPACE, } from '@angular/cdk/keycodes';
import { Moment } from 'moment';
import moment from 'moment';
import { AuthNewService } from '../../service/auth-new.service';
import { delay } from 'rxjs/operators';


@Component({
  selector: 'app-new-analysis',
  templateUrl: './new-analysis.component.html',
  styleUrls: ['./new-analysis.component.scss']
})
export class NewAnalysisComponent implements OnInit {


  pageTitle = 'Analyze Report/Data';
  signedInUser: User;
  analysisFormGroup: FormGroup;
  analysisFormGroup_ADA: FormGroup;
  analysisFormGroup_RSG: FormGroup;
  lts80_dialog: FormControl = new FormControl();

  visible = true;
  selectable = true;
  removable = true;


  // analytes: FormArray
  analysisDetails: AnalysisDetails;
  editDetails: AnalysisDetails;
  post: any = '';

  pattern1 = '^([a-zA-Z0-9\\s]+[-&/])*([a-zA-Z0-9\\s])+?$';
  stdConc_pattern = '^[-]?[0-9]+([,.][0-9]+)?$';
  stdConc_validator: boolean;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  Tags: string[] = [];
  maxDate;
  multipleAnalyteError = false;

  addOnBlur = true;
  isLinear = true;
  showTable = false;
  countTable = false;
  isSelected = false;
  subtype: AnalysisDetails;

  templateData: Templates[];
  tableNames: any[];
  selctables: any = [];
  tableNamesList: any[];

  openSecondMatStep: string;
  key: any = '';
  selectedTablesList = [];

  constructor(private formBuilder: FormBuilder,
    private _sharedService: SharedService,
    private authService: AuthService,
    private router: Router,
    private dropDownService: DropDownService,
    private newAuthService: AuthNewService,
    private ref: ChangeDetectorRef) {

    this._sharedService.emitChange(this.pageTitle);
  }

  ngOnInit() {
    const currentDate = moment().toDate();
    this.maxDate = currentDate;
    this.newAuthService.getCurrentUser();
    this.newAuthService.getCurrentLoginUser.pipe(delay(1000)).subscribe((user: any) => {
      // console.log('User Data on New Alalysis Page', user);
      this.signedInUser = user;
      if (!this.signedInUser) {
        this.router.navigate(['/extra-layout/signin']);
        return;
      }
    });

    this.createForm();
  }

  editform() {
    this.post = '';
    this.editDetails = this.analysisDetails;
  }


  editdetails(payload) {
    this.post = payload;
  }




  displaydetails(item) {
    this.analysisDetails = item;
    this.post = this.analysisDetails;
    this.openSecondMatStep = this.analysisDetails.analysisType;
  }

  createForm() {
    this.analysisFormGroup = this.formBuilder.group({
      analysisType: [null, Validators.required],
    });

    this.analysisFormGroup_ADA = this.formBuilder.group({
      analysisType: ['ADA'],
    })

    this.analysisFormGroup_RSG = this.formBuilder.group({
      analysisType: ['RSG'],
    });
    this.ref.detectChanges();
  }

  get formControls() { return this.analysisFormGroup.controls; }
  resetAnalysis() {
    console.log('RESET CAlled');
    // this.getData();
  }


  getDisplayString(key) {
    return this.dropDownService.nameDescMap.get(key);
  }

  getDropDownValues(key) {
    return this.dropDownService.dropDownsMap.get(key);
  }




}
