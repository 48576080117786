



<section>
  
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4">
        <mat-card style="height: 300px;">
          <form>
            <h4>Select a table for correction!</h4>
            <mat-form-field>
              <mat-label>Tables</mat-label>
              <mat-select name="Tables" [disabled]="!tableNames" disableOptionCentering>
                <mat-option *ngFor="let table of tableNames" [value]="table.description" (click)="tableValue(table.name)">
                  {{table.description}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </form>
        </mat-card>
      </div>
      <div class="col-md-8">
        <mat-card>
          <h3 class="text-center" [hidden]="selected">Please select table to populate the data.</h3>
          <div class="col-lg-12">
            <div *ngIf="flag">
              <app-dynamic-form-builder *ngIf="enabled; else spin" [fields]="fields[0]" (formData)="submittedData($event)"></app-dynamic-form-builder>
            </div>
          </div>
        </mat-card>
        
      </div>
    </div>
  </div>
</section>
<ng-template #spin>
  <div class="main-div spinner">
      <mat-spinner></mat-spinner>
  </div>
</ng-template>