<card *ngIf="searchResults">

    <!--<p><strong>Analysis ID: {{searchResults.analysisId}}</strong></p>-->
    <p><strong>Analysis Type: {{getDisplayString(searchResults.analysisType)}}</strong></p>


    <div  *ngIf="searchResults.analysisStatus != 'Error'">
        <div *ngIf="searchResults.analysisResult.trim() != ''; else spin">
            <!-- <div [style.display]="searchResults.analysisResult.trim() != ''? 'inline' : 'none'"> -->
            <a target="_blank" href="{{searchResults.analysisResultFile}}">
                <p><strong>Analysis Report</strong></p>
                <file [type]="'doc'" [title]="'Output'"></file>
            </a>
        </div>
        <ng-template #spin>
            <div>
                <mat-spinner></mat-spinner>
            </div>
        </ng-template>


        <div>
            <div><strong>Disclaimer</strong></div>
            <div style='font-size:80%;'>Red Thread is not a replacement for any quality control or quality
                assurance process. Ariadne does not guarantee the accuracy of the
                results of the software. To the fullest extent permitted under
                applicable law Ariadne is not liable for any event or loss related
                to the reliance on Red Thread or the output produced by Red Thread.</div>
        </div>
    </div>
    <div *ngIf="searchResults.analysisStatus === 'Error'">
        <div class="main-div">
            <app-page-500></app-page-500>
        </div>
    </div>
    <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <strong>Uploaded Files</strong>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngFor="let localAnalyte of analysisDetails.analytes">
                <p><strong>Analyte Name: {{localAnalyte.analyteName}}</strong></p>
                <div *ngFor="let localUpload of localAnalyte.uploadObjs">
                    <div *ngIf="localUpload.files.length > 0"><strong>{{localUpload.uploadType}}</strong>
                        <ol>
                            <div *ngFor="let localFile of localUpload.files">
                                <li>{{localFile.name}}</li>
                            </div>
                        </ol>
                    </div>
                </div>


            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion class="example-headers-align">
        <mat-expansion-panel *ngFor="let result of results let i = index" [attr.data-index]="i" [expanded]="step === i"
            (opened)="setStep(i)" hideToggle>

            <mat-expansion-panel-header>
                <mat-panel-title>
                    <p><strong>{{result.heading}}</strong></p>
                </mat-panel-title>
                <div class="badge">
                    <mat-panel-description>
                        <badge [customColor]="'#C5EEB2'">Green {{result.green}}</badge>
                        <badge [customColor]="'#F8F8A9'">Yellow {{result.yellow}}</badge>
                        <badge [customColor]="'#EEC2B7'">Red {{result.red}}</badge>
                    </mat-panel-description>
                </div>
            </mat-expansion-panel-header>
            <div *ngFor="let resultItem of result.resultItem">
                <!-- this code is creating the templates. -->
                <p><strong><u>{{resultItem.tabletype}}</u></strong></p>
                <div *ngFor="let item of resultItem.item">

                    <p>
                        <strong>
                            <!-- <badge [customColor]="item.color">
                                {{item.color}}
                            </badge> -->
                            <div *ngIf="item.color=='Red'">
                                <badge [customColor]="'#EEC2B7'">{{item.color}}</badge>
                            </div>

                            <div *ngIf="item.color=='Yellow'">
                                <badge [customColor]="'#F8F8A9'">{{item.color}}</badge>
                            </div>

                            <div *ngIf="item.color=='Green'">
                                <badge [customColor]="'#C5EEB2'">{{item.color}}</badge>
                            </div>

                        </strong>
                    </p>
                    <div *ngIf="item.color === 'Graph'" fxFlex="25%">
                        <br>
                        <span style="color: royalblue; cursor: pointer;" (click)="openGraph(item.message)">
                            Click for Visualization
                        </span>
                        <br>
                        <!--                                <span  style="color: royalblue; cursor: pointer;"  (click)="openGraph2(item.message)">
                                        Click to open - Type 2 Graph
                                    </span>-->
                    </div>

                    <div *ngFor="let text of item.message">
                        <div *ngIf="item.color === 'Table' || item.color === 'Graph'; else elseBlock">
                            <div *ngIf="item.color === 'Table';">
                                <div [innerHTML]="text" class="table table-striped my-5 mx-5"></div>
                            </div>
                        </div>
                        <ng-template #elseBlock>
                            <ul class="nolist">
                                <li>{{text}}</li>
                            </ul>
                        </ng-template>



                    </div>
                    <br>
                </div>
                <br>
            </div>
            <br>

            <!-- </div> -->

        </mat-expansion-panel>
    </mat-accordion>




</card>

<ng-template #spin>
    <div>
        <mat-spinner style="margin:0 auto;" mode="indeterminate" [diameter]="200"></mat-spinner>
    </div>
</ng-template>
