<!--
<section>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <mat-card class="my-3 chartCards">
          <div echarts [options]="options" class="demo-chart" id="e"></div>
        </mat-card>
      </div>
    </div>
  </div>
</section>
-->


<!--<div  fxFlex="25%" *ngFor="let gg of graph_options">-->

<!--  <div echarts [options]="gg" class="demo-chart" id="e"></div>-->
<!--</div>-->
<!--{{options}}-->



<!--<section>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <mat-card class="my-3 chartCards">
          <div echarts [options]="graph_options[0]" class="demo-chart" id="e"></div>
        </mat-card>
      </div>
    </div>
  </div>
</section>-->

<div class="content" fxFlex fxLayout="row wrap" style="width: 100%; height:460px;">
  <div><button class="btn btn-danger btn-sm" (click)="onCancel()">X</button></div>
  <div class="col-md-12"></div>
  <div class="col-lg-6 m-auto" *ngFor="let graph of graphData">
    <mat-card class="mat-elevation-z5 my-2">
      <!-- <highcharts-chart [Highcharts]="highcharts" [options]="graph"
        style="width: 100%; display: flex;"></highcharts-chart> -->
        <img [src]="graph" alt="nothing">
    </mat-card>
    
  </div>
</div>