<div class='container-fluid p-0'>   
    <div class='col-12 p-0'>  
        <ngb-carousel>  
            <ng-template ngbSlide>
                <img src="../assets/img/1.jpg" alt="Delhi Metro">  
                <div class="carousel-caption">  
                    <!-- <h3 style="color: yellow">Delhi Metro</h3>   -->
                </div>  
            </ng-template>  
            <ng-template ngbSlide>  
                <img src="../assets/img/2.jpg" alt="Bangalore Metro">  
                <div class="carousel-caption">  
                    <!-- <h3 style="color: yellow">Bangalore Metro</h3>   -->
                </div>  
            </ng-template>  
            <ng-template ngbSlide>  
                <img src="../assets/img/3.jpg" alt="Channai Metro">  
                <div class="carousel-caption">  
                    <!-- <h3 style="color: yellow">Channai Metro</h3>   -->
                </div>  
            </ng-template>  
            <ng-template ngbSlide>  
                <img src="../assets/img/5.jpg" alt="Jaipur Metro">  
                <div class="carousel-caption">  
                    <!-- <h3 style="color: yellow">Jaipur Metro</h3>   -->
                </div>  
            </ng-template>
        </ngb-carousel>  
    </div>  
  
</div>