<div
  class="card-wrap"
  [ngClass]="{
    'success-card': bgColor === 'success',
    'info-card': bgColor === 'info',
    'warning-card': bgColor === 'warning',
    'danger-card': bgColor === 'danger',
    'success-color-card': color === 'success',
    'info-color-card': color === 'info',
    'warning-color-card': color === 'warning',
    'danger-color-card': color === 'danger',
    'bg-image-card': bgImage,
    'outline-card': outline
  }"
  [ngStyle]="{
   'background-color': customBgColor,
   'background-image': 'url(' + bgImage + ')',
   'border-color': customBgColor,
   'color': customColor
  }"
>
  <div
    class="card-header"
    *ngIf="title"
    [ngClass]="{
      'text-right': align === 'right',
      'text-center': align === 'center'
    }"
  >{{title}}</div>

  <div
    class="card-content"
    [ngClass]="{
      'text-right': align === 'right',
      'text-center': align === 'center'
    }"
    [ngStyle]="{'padding': indents}"
  >
    <ng-content></ng-content>
  </div>
</div>