import { ChangeDetectorRef, Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DropDownService } from '../../../service/drop-down.service';
import { AnalysisDetails, DropDown, SearchResult, Templates, AnalysisDetailsSLR } from '../../../model/analysis-details.model';
import { TemplateService } from '../../../service/template.service';
import { SharedService } from '../../../layouts/shared-service';
import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';

@Component({
  selector: 'app-small-large-report',
  templateUrl: './small-large-report.component.html',
  styleUrls: ['./small-large-report.component.scss']
})
export class SmallLargeReportComponent implements OnInit {

  @Input() analysistype: string;
  @Input() editvalues: any;
  @Output() formvalue = new EventEmitter<any>();
  pageTitle = 'Analyze Report/Data';
  analysisFormGroup: FormGroup;
  maxDate;
  // lts80_dialog: FormControl = new FormControl();
  analysisDetails: AnalysisDetails;
  subtype: AnalysisDetails;
  tableNamesList: any[];
  tableNames: any;
  showTable = false;
  countTable = false;
  selectedTablesList = [];
  post: any;
  tableparamValue: [];
  GenTablesList = [];
  ValTablesList = [];
  SumTablesList = [];
  Smparameterlist = [];
  Lmparameterlist = [];
  selectedValTables = [];
  selectedSummaryTables = [];
  selectedGenralTables = [];
  customdisable: boolean = true;
  LLOQValue=[];
  ULOQValue=[];
  constructor(
    private formBuilder: FormBuilder,
    private _sharedService: SharedService,
    private dropDownService: DropDownService,
    private templateService: TemplateService,
    private ref: ChangeDetectorRef
  ) {
    this._sharedService.emitChange(this.pageTitle);
  }

  ngOnInit(): void {
    this.createForm();
    this.getData();
  }


  createForm() {
    this.analysisFormGroup = this.formBuilder.group({
      analysisType: [this.analysistype],
      analysisSubtype: [null],
      selectedTablesList: [null],
      projectCodeSA: [''],
      projectCodeMV: [null],
      regressionModel: [null],
      lloq: [null],
      uloq: [null],
      re_type: [true],
      re_value: [null],
      lts80_dialog: [null],
      lts20_dialog: [null],
      extraction_dialog: [null],
      benchtop_dialog: [null],
      freeze_thaw_dialog: [null],
      multipleAnalytes: ['No'],
      first_patient_dialog: [null],
      sampleMatrix: ['', [Validators.pattern('^[a-zA-Z]*$')]],
      antiCoagulant: ['', [Validators.pattern('^[A-Za-z0-9? ,_-]+$')]],
      species: ['', [Validators.pattern('^[a-zA-Z]*$')]],
      samplePrep: [null],
      sampleDate: ['', [Validators.max(this.maxDate)]],
      LLOQValue: [null],
      ULOQValue: [null],
      analyteNames: this.formBuilder.array([this.formBuilder.control([])]),
      selectedGenralTables: [null],
      selectedSummaryTables: [null],
      selectedValTables: [null],
      tableparamValue: [null],
      files:[null],
      file_path: [null],

    });
    this.ref.detectChanges();


    if (this.editvalues !== undefined) {
      const SLRvalue: AnalysisDetailsSLR = new AnalysisDetailsSLR()
      SLRvalue.analysisType = this.analysistype;
      SLRvalue.analysisSubtype = this.editvalues.analysisSubtype;
      SLRvalue.selectedTablesList = this.editvalues.selectedTablesList;
      SLRvalue.projectCodeSA = this.editvalues.projectCodeSA;
      SLRvalue.projectCodeMV = this.editvalues.projectCodeMV;
      SLRvalue.regressionModel = this.editvalues.regressionModel;
      SLRvalue.lloq = this.editvalues.lloq;
      SLRvalue.uloq = this.editvalues.uloq;
      SLRvalue.re_type = this.editvalues.re_type;
      SLRvalue.re_value = this.editvalues.re_value;
      SLRvalue.lts80_dialog = this.editvalues.lts80_dialog;
      SLRvalue.lts20_dialog = this.editvalues.lts20_dialog;
      SLRvalue.extraction_dialog = this.editvalues.extraction_dialog;
      SLRvalue.benchtop_dialog = this.editvalues.benchtop_dialog;
      SLRvalue.freeze_thaw_dialog = this.editvalues.freeze_thaw_dialog;
      SLRvalue.multipleAnalytes = this.editvalues.multipleAnalytes;
      SLRvalue.first_patient_dialog = this.editvalues.first_patient_dialog;
      SLRvalue.sampleMatrix = this.editvalues.sampleMatrix;
      SLRvalue.antiCoagulant = this.editvalues.antiCoagulant;
      SLRvalue.species = this.editvalues.species;
      SLRvalue.samplePrep = this.editvalues.samplePrep;
      SLRvalue.sampleDate = this.editvalues.sampleDate;
      SLRvalue.analyteNames = this.editvalues.analyteNames;
      SLRvalue.selectedGenralTables = this.editvalues.selectedGenralTables;
      SLRvalue.selectedSummaryTables = this.editvalues.selectedSummaryTables;
      SLRvalue.selectedValTables = this.editvalues.selectedValTables;
      SLRvalue.tableparamValue = this.editvalues.tableparamValue;
      for (let i = 1; i < this.editvalues.analyteNames.length; i++) {
        this.analyteNames.push(this.formBuilder.control(''));
      }
      SLRvalue.LLOQValue = this.editvalues.LLOQValue;
      // for (let i = 1; i < this.editvalues.LLOQValue.length; i++) {
      //   this.LLOQValue.push(this.formBuilder.control(''));
      // }

      SLRvalue.ULOQValue = this.editvalues.LLOQValue;
      // for (let i = 1; i < this.editvalues.ULOQValue.length; i++) {
      //   this.ULOQValue.push(this.formBuilder.control(''));
      // }

      this.analysisFormGroup.setValue(SLRvalue);
    }

  }


  get formControls() { return this.analysisFormGroup.controls; }

  onSubmit(post: any) {
    console.log('Submit for Analysis', post)
    // tslint:disable-next-line: max-line-length

    this.analysisDetails = Object.assign({}, this.analysisFormGroup.value);
    this.analysisDetails.userId = sessionStorage.getItem('username');
    console.log('Submit - analysis object value', this.analysisDetails);
    if (this.analysisDetails.multipleAnalytes === 'Yes') {
      this.analysisDetails.multipleAnalytes = 'True'
    } else {
      this.analysisDetails.multipleAnalytes = 'False'
    }

    this.analysisDetails.analysisId = (new Date).getTime().toString();
    this.analysisDetails.selectedValTables = this.selectedValTables.map(res =>
      res = res.table_id);
    this.analysisDetails.selectedSummaryTables = this.selectedSummaryTables.map(res =>
      res = res.table_id);
    this.analysisDetails.selectedGenralTables = this.selectedGenralTables.map(res =>
      res = res.table_id);
    this.post = post;
    this.analysisDetails.LLOQValue=this.LLOQValue;
    this.analysisDetails.ULOQValue=this.ULOQValue;
    if (this.tableNames[0].parameter.parameter_value !== this.tableparamValue) {
      // this.analysisDetails.tableparamValue = this.tableNames.map(res =>
      //   res = res.parameter);
      if (this.analysistype == 'SMR') {
        this.analysisDetails.tableparamValue = this.Smparameterlist;
      }
      else
        this.analysisDetails.tableparamValue = this.Lmparameterlist;

    }

    this.formvalue.emit(this.analysisDetails);


  }

  getData() {

    const analysis_type = this.analysistype;
    if (this.editvalues !== undefined) {
      console.log(this.editvalues.tableparamValue)
      const analysis_subtype = this.editvalues.analysisSubtype;
      // console.log(analysis_type, analysis_subtype);
      this.templateService.getTableData().subscribe((table_names: any) => {
        this.tableNames = table_names;
        this.tableNames = Object.entries(this.tableNames).map((e) => ({ [e[0]]: e[1] }));
        console.log("converted object into array of objects", this.tableNames);

        this.tableNames[0].parameter.forEach((res, index) => {
          if (res.analysis_type == 'sm') {
            this.Smparameterlist.push(this.tableNames[0].parameter[index]);
            this.Smallvaluechange(this.editvalues.tableparamValue, null);
          }
          else
            this.Lmparameterlist.push(this.tableNames[0].parameter[index]);
          this.Largevaluechange(this.editvalues.tableparamValue, null);
        })

        this.tableNames[1].table_data.forEach((res, index) => {
          if (res.table_type == 'gen') {
            this.GenTablesList.push(this.tableNames[1].table_data[index]);

          }
          else if (res.table_type == 'mv') {
            this.ValTablesList.push(this.tableNames[1].table_data[index]);

          }
          else if (res.table_type == 'sa') {
            this.SumTablesList.push(this.tableNames[1].table_data[index]);
          }
        })
        this.GenTablesList = [...[{ table_name: 'Select All', table_type: 'Select All', checked: false }], ...this.GenTablesList];
        this.ValTablesList = [...[{ table_name: 'Select All', table_type: 'Select All', checked: false }], ...this.ValTablesList];
        this.SumTablesList = [...[{ table_name: 'Select All', table_type: 'Select All', checked: false }], ...this.SumTablesList];
        this.selectedGeneralTables(this.editvalues.selectedGenralTables);
        this.selectedSampleTables(this.editvalues.selectedSummaryTables);
        this.selectedValidTables(this.editvalues.selectedValTables);
      });
    }
    else {
      const analysis_subtype = this.analysisFormGroup.value?.analysisSubtype;
      console.log(analysis_type, analysis_subtype);

      this.templateService.getTableData().subscribe((table_names: any) => {
        this.tableNames = table_names;
        console.log(this.tableNames);
        this.tableNames = Object.entries(this.tableNames).map((e) => ({ [e[0]]: e[1] }));
        console.log("converted object into array of objects", this.tableNames);
        this.tableNames[0].parameter.forEach((res, index) => {
          if (res.analysis_type == 'sm') {
            this.Smparameterlist.push(this.tableNames[0].parameter[index])
          }
          else
            this.Lmparameterlist.push(this.tableNames[0].parameter[index])
        })

        // console.log(this.Lmparameterlist,this.Smparameterlist)

        this.tableNames[1].table_data.forEach((res, index) => {
          if (res.table_type == 'gen') {
            this.GenTablesList.push(this.tableNames[1].table_data[index]);

          }
          else if (res.table_type == 'mv') {
            this.ValTablesList.push(this.tableNames[1].table_data[index]);

          }
          else if (res.table_type == 'sa') {
            this.SumTablesList.push(this.tableNames[1].table_data[index]);
          }
        })
        this.GenTablesList = [...[{ table_name: 'Select All', table_type: 'Select All', checked: false }], ...this.GenTablesList];
        this.ValTablesList = [...[{ table_name: 'Select All', table_type: 'Select All', checked: false }], ...this.ValTablesList];
        this.SumTablesList = [...[{ table_name: 'Select All', table_type: 'Select All', checked: false }], ...this.SumTablesList];
        // console.log(this.GenTablesList)
      });

    }
  }

  changecustomlayout() {
    this.customdisable = false;
    console.log(this.customdisable);
  }
  changedefaultlayout() {
    this.customdisable = true;
    console.log(this.customdisable);
  }

  Smallvaluechange(event, index) {
    if (this.editvalues !== undefined) {
      for (let i = 0; i < this.Smparameterlist.length; i++) {
        this.Smparameterlist[i].parameter_value = this.editvalues.tableparamValue[i].parameter_value;
      }
    }
    else {
      this.tableparamValue = []
      this.tableparamValue = event?.target?.value;
      // this.tableNames[0].parameter[index].parameter_value = this.tableparamValue;
      this.Smparameterlist[index].parameter_value = this.tableparamValue;
    }
  }

  Largevaluechange(event, index) {
    if (this.editvalues !== undefined) {
      for (let i = 0; i < this.Lmparameterlist.length; i++) {
        this.Lmparameterlist[i].parameter_value = this.editvalues.tableparamValue[i]?.parameter_value;
      }
    }
    else {
      this.tableparamValue = []
      this.tableparamValue = event?.target?.value;
      this.Lmparameterlist[index].parameter_value = this.tableparamValue;
    }
  }


  selectedGeneralTables(event) {
    // console.log(event, "passed value");
    if (this.editvalues !== undefined) {

      for (let i = 1; i < this.GenTablesList.length; i++) {
        for (let j = 0; j < event.length; j++) {
          // console.log(this.GenTablesList[i].table_name,event[j])
          if (this.GenTablesList[i].table_name == event[j]) {
            this.GenTablesList[i].checked = true
          }
        }

      }

    }
    else {
      if (event.option.value === 'Select All') {
        this.GenTablesList.forEach((v) => {
          if (!event.option.selected) {
            v.checked = false
          } else {
            v.checked = true;
          }

        })
      } else {
        this.GenTablesList.forEach((v) => {
          if (event.option.value === v.table_name && v.checked === true) {
            v.checked = false;
            this.GenTablesList[0].checked = false;

          } else if (event.option.value === v.table_name) {
            v.checked = true;

          }
        })

      }
    }

    const data = this.GenTablesList.filter((item) => {
      if (item.checked === true && item.table_name !== 'Select All') {
        return (item.table_name);
      }
    })
    console.log(data)
    this.selectedGenralTables = []
    this.selectedGenralTables = data;

  }

  selectedValidTables(event) {
    // console.log(event, "passed value");
    if (this.editvalues !== undefined) {

      for (let i = 1; i < this.ValTablesList.length; i++) {
        for (let j = 0; j < event.length; j++) {
          // console.log(this.GenTablesList[i].table_name,event[j])
          if (this.ValTablesList[i].table_name == event[j]) {
            this.ValTablesList[i].checked = true
          }
        }

      }

    }
    else {
      if (event.option.value === 'Select All') {
        this.ValTablesList.forEach((v) => {
          if (!event.option.selected) {
            v.checked = false
          } else {
            v.checked = true;
          }

        })
      } else {
        this.ValTablesList.forEach((v) => {
          if (event.option.value === v.table_name && v.checked === true) {
            v.checked = false;
            this.ValTablesList[0].checked = false;
          } else if (event.option.value === v.table_name) {
            v.checked = true;

          }
        })
      }
    }
    const data = this.ValTablesList.filter((item) => {
      if (item.checked === true && item.table_name !== 'Select All') {
        return (item.table_name);
      }
    })
    console.log(data)
    this.selectedValTables = []
    this.selectedValTables = data;
  }

  selectedSampleTables(event) {
    // console.log(event, "passed value");
    if (this.editvalues !== undefined) {

      for (let i = 1; i < this.SumTablesList.length; i++) {
        for (let j = 0; j < event.length; j++) {
          // console.log(this.GenTablesList[i].table_name,event[j])
          if (this.SumTablesList[i].table_name == event[j]) {
            this.SumTablesList[i].checked = true
          }
        }

      }

    }
    else {
      if (event.option.value === 'Select All') {
        this.SumTablesList.forEach((v) => {
          if (!event.option.selected) {
            v.checked = false
          } else {
            v.checked = true;
          }
        })
      }
      else {
        this.SumTablesList.forEach((v) => {
          if (event.option.value === v.table_name && v.checked === true) {
            v.checked = false;
            this.SumTablesList[0].checked = false;
          } else if (event.option.value === v.table_name) {
            v.checked = true;

          }
        })
      }
    }
    const data = this.SumTablesList.filter((item) => {
      if (item.checked === true && item.table_name !== 'Select All') {
        return (item.table_name);
      }
    })
    console.log(data)
    this.selectedSummaryTables = []
    this.selectedSummaryTables = data;
  }


  getDropDownValues(key) {
    return this.dropDownService.dropDownsMap.get(key);
  }

  get analyteNames() {
    return this.analysisFormGroup.get('analyteNames') as FormArray;   
  }

  addAnalyte() {
    this.analyteNames.push(this.formBuilder.control(''));
  }

  addLLOQ(LLOQValue) {
    console.log(LLOQValue.target.value)
    LLOQValue=LLOQValue.target.value
    this.LLOQValue.push(LLOQValue);
    console.log(this.LLOQValue)

  }


  addULOQ(ULOQValue) {
    console.log(ULOQValue.target.value)
    ULOQValue=ULOQValue.target.value
    this.ULOQValue.push(ULOQValue);
    console.log(this.ULOQValue)
  }


  removeAnalyte(index) {
    this.analyteNames.removeAt(index);
  }

  resetAnalysis() {
    console.log('RESET CAlled');

  }

  multipleAnalyteValues() {
    const multipleChecked = this.analysisFormGroup.value.multipleAnalytes;
    const count = this.analysisFormGroup.value.analyteNames;
    console.log(multipleChecked);
    console.log(count);

    if (multipleChecked === 'True' && count.length === 1) {
      console.log('error');
      // tslint:disable-next-line: no-unused-expression
      this.analysisFormGroup.invalid;
    } else {
      console.log('no error');
    }

  }

}
