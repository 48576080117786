<div *ngIf="name === 'HELP'">
  <h6 mat-dialog-title>Help<button style="float: right;" mat-button mat-dialog-close><i class="fa fa-times"
        aria-hidden="true"></i></button></h6>
  <div mat-dialog-content>
    <h2>
      Enabling biopharma to build a complete bioanalytical profile at the earliest stages of drug development.
    </h2>
    <br>
    <h3>The Problem </h3><hr>
    <p>
      Today’s bioanalysis presents major challenges for even the most experienced scientists. The sheer volume of data
      makes it all too easy to miss errors, signals and important patterns. Years of bioanalytical data review combined
      with recent advancements in natural language processing and machine learning applications provided the foundation
      for Ariadne software. Our team of bioanalytical and data scientists believe the same data-driven advancements
      taking place in the clinical trials and laboratory informatics spaces can be applied to optimize early drug
      development. Using the right tools, we believe scientists can better identify and predict risk, accelerate
      outcomes and optimize their investments.
    </p>
    <h3>The Solution</h3><hr>
    <p>
      Red Thread, Ariadne’s premiere product, is your ally for bioanalytical data review and risk management. Powered by
      deep learning techniques and designed by bioanalytical scientists, Red Thread is a force multiplier to help you
      optimize time, cost and quality in early drug development.
    </p>
    <ul>
      <li>Automation dramatically reduces time-intensive and manual data review processes.</li>
      <li>High sensitivity analysis allows for the detection of important patterns or near-failures which are easily
        overlooked in manual reviews.</li>
      <li>A tiered alert system flags area of concern within the data.</li>
      <li>An intuitive graphical interface allows users to drill down into Red Thread’s analysis.</li>
    </ul>
    <h3>Precision</h3><hr>
    <p>
      Red Thread delivers objectivity and rigor to scientific review, quality control, and quality assurance. It
      automates time-intensive processes for large volume data review and regulatory inspection preparation. Virtually
      every format, from raw data to finalized study report is supported by Red Thread.
    </p>
    <h3>Speed</h3><hr>
    <p>Advanced analytics provide rapid and efficient data review, with the ability to quickly detect trends of
      importance, or potential compliance issues. Red Thread dramatically reduces time-consuming tasks for scientific
      staff. High volume bioanalytical data reviews can be completed in hours, not days.

    </p>
    <h3>Depth</h3><hr>
    <p>
      High sensitivity analysis offers granularity that goes way beyond failure identification. Red Thread helps users
      find root causes and identify near-failure patterns, often the source of longer-term threats to drug development
      integrity.
    </p>
  </div>
</div>