
<section name="data section" class="my-5">

  <div class="col-md-10">

    <mat-form-field appearance="fill" class='matwidth'>
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="picker" >
        <input matStartDate placeholder="Start date"  #dateRangeStart>
        <input matEndDate placeholder="End date"  #dateRangeEnd  >
      </mat-date-range-input>
      <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
        <br>
    <button class="btn-success" (click)='selectedDates(dateRangeStart, dateRangeEnd)' >submit</button>
    
  </div>

 
  <div class="col-md-10 my-5">
    <h3>Analysis Data</h3>
    <div *ngIf="previousAnalysisKeys.length > 0; else spin">
      <app-details [searchResults]="previousAnalysisKeys"></app-details>
    </div>
  </div>
</section>

<ng-template #spin>
  <div>
    <mat-spinner
      style="margin: 0 auto"
      mode="indeterminate"
      [diameter]="200"
    ></mat-spinner>
  </div>
</ng-template>

<ng-template #noDataFound>
    <h3>No date found for {{ signedInUser.username }}</h3>
</ng-template>


<router-outlet name="print"></router-outlet>
