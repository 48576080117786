<div>

  <div class="main-div">

    <div *ngIf="loadPage; else spin">
      <div *ngIf="dashboard_data.length > 1; else welcome">
        <app-charts [dashboard]="dashboard_data"></app-charts>
      </div>
      <ng-template #welcome>
        <div>
          <h2>Welcome to Red Thread</h2>
          <h3>Press "Analyze and Report/Data" to start a new analysis</h3>
          <div class="container">
            <iframe class="responsive-iframe" src="https://www.youtube.com/embed/pUNWvg4cDe0" frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </ng-template>
    </div>

    <ng-template #spin>
      <div>
        <mat-spinner style="margin:0 auto;" mode="indeterminate" [diameter]="200"></mat-spinner>
      </div>
    </ng-template>
  </div>
</div>


