<card>
    <div class="row mb-2">
        <div class="col-md-4 col-xl-3" style="height: 45px;">
            <a href="https://www.ariadnesolutions.com/" target="_blank"><img class="img-fluid"
                    src="assets/img/Red Thread_Clear.jpg"></a>
        </div>
        <div class="col-md-4 col-xl-5" style="height: 45px;">
        </div>
        <div class="col-md-4 col-xl-2 mb-2" style="height: 45px; margin-left: 6em;">
            <br>
            <mat-chip-list style="float: right;">
                <mat-chip color="accent" style="font-size: larger;cursor: pointer;"
                    [routerLink]="['/extra-layout/signin']" selected>Sign In</mat-chip>
                <mat-chip color="primary" style="font-size: larger;cursor: pointer;"
                    [routerLink]="['/extra-layout/home']" selected>Home</mat-chip>
            </mat-chip-list>
        </div>
    </div>
</card>

<card>

    <div *ngIf="isLoading">
        <app-verification [model]="model"></app-verification>
    </div>
    <div *ngIf="!isLoading">
        <card class="mb-0">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 offset-md-3 m-auto">
                        <h3>Red Thread User Registration</h3>
                        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" novalidate>

                            <div class="form-group">
                                <mat-form-field>
                                    <mat-label>User Name</mat-label>
                                    <input type="text" matInput formControlName="username" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                                    <mat-error *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                        <div *ngIf="f.username.errors.required">User Name is required</div>
                                        <div *ngIf="f.username.errors.minlength">User Name must be at least 6 characters
                                        </div>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-group">
                                <mat-form-field>
                                    <mat-label>Name</mat-label>
                                    <input type="text" matInput formControlName="name" class="form-control"
                                        placeholder="Name" name="Name"
                                        [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                                    <mat-error *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                        <div *ngIf="f.name.errors.required">Name is required</div>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group">
                                <mat-form-field>
                                    <mat-label>Phone</mat-label>
                                    <input type="text" matInput formControlName="phone" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                                    <mat-error *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                        <div *ngIf="f.phone.errors.required">Phone is required</div>
                                        <div *ngIf="f.phone.errors.pattern">Please enter valid phone number</div>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group">
                                <mat-form-field>
                                    <mat-label>Email</mat-label>
                                    <input type="text" matInput formControlName="email" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                    <mat-error *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-group">
                                <mat-form-field>
                                    <mat-label>Organization</mat-label>
                                    <input type="text" matInput formControlName="organization" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.organization.errors }" />
                                    <mat-error *ngIf="submitted && f.organization.errors" class="invalid-feedback">
                                        <div *ngIf="f.organization.errors.required">Organization is required</div>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <!-- <div class="form-group">
                                <mat-form-field>
                                    <mat-label>Access Code **</mat-label>
                                    <input type="text" matInput formControlName="promotionCode" class="form-control"
                                           [ngClass]="{ 'is-invalid': submitted && f.promotionCode.errors }"/>
                                    <mat-error *ngIf="submitted && f.promotionCode.errors" class="invalid-feedback">
                                        <div *ngIf="f.promotionCode.errors.required">Access Code is required</div>
                                    </mat-error>
                                </mat-form-field>
                            </div> -->

                            <div class="form-group">
                                <mat-form-field>
                                    <mat-label>Password</mat-label>
                                    <input type="password" matInput formControlName="password" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                    <mat-error *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                        <div *ngIf="f.password.errors.required">Password is required</div>
                                        <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters
                                        </div>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group">
                                <mat-form-field>
                                    <mat-label>Confirm Password</mat-label>
                                    <input type="password" matInput formControlName="confirmPassword"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
                                    <mat-error *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                        <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required
                                        </div>
                                        <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-group">
                                <input type="checkbox" formControlName="disclaimer"
                                    [ngClass]="{ 'is-invalid': submitted}" /><span>
                                    By checking this box, I acknowledge that I have read and agree to these
                                    <a href="/assets/data/EULA.pdf" target="_blank"> Terms of Service and EULA.</a>
                                </span>
                                <div *ngIf="submitted && f.disclaimer.errors" class="invalid-feedback">
                                    <div *ngIf="f.disclaimer.errors.required">Select the checkbox</div>
                                </div>
                            </div>

                            <!--                            <a (click)="goToLink('/assets/data/EULA.pdf')">page link</a>-->
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-4"><button mat-raised-button color="accent">Register</button>
                                    </div>
                                    <div class="col-md-6"><button mat-raised-button color="primary"
                                            (click)="getVerificationPage()"> I have Verification Code</button></div>
                                </div>
                            </div>
                        </form>
                        <br>
                        <div class="row" *ngIf="didFail">
                            <card [bgColor]="'danger'" [color]="'danger'" [outline]="true">
                                <p><strong>Error: </strong>{{failMessage}}</p>
                            </card>
                        </div>
                        <br>
                        <div>
                            <p><small>** In order to sign up for Red Thread, you must have received an Access Code from
                                    an
                                    Ariadne Solutions representative. If you do not have an access code, please contact
                                    <a href="mailto:sales@ariadnesoutions.com">sales@ariadnesoutions.com</a> or <a
                                        href="mailto:support@ariadnesolutions.com">support@ariadnesolutions.com</a>
                                </small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </card>
    </div>

    <br>

    <br>
    <!--<div *ngIf="isLoading">-->
</card>
<app-footer></app-footer>